import { useQuery } from "@apollo/react-hooks";
import {
  Flex,
  PageLoader,
  Sidebar,
  SidebarPrimaryNavLink,
  SidebarItemGroup,
  SidebarNavLink,
  Box,
  PageHeading,
  Button,
} from "@skodel/sk-ui";
import { MaxWidthContainer } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useState } from "react";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";

import AddBulkUsersModal from "../components/bulk-import-flow/Modal";
import GroupSettings from "./GroupSettings";
import GroupUsers from "./GroupUsers";
import { GetGroup } from "./types";

export const pushWithHook = (...args: any) => {
  // window.scrollTo(0, 0);
  args[0].push(args[1]);
};

const GET_GROUP = loader("./GetGroup.graphql");

export const getNewLocation = (feedParams: any, location: any) => {
  const paramsWithoutNullOrEmptyValues = Object.fromEntries(
    Object.entries(feedParams)
      .filter(([_, v]) => v !== null && v !== "")
      .map((v: any) => {
        if (Array.isArray(v[1])) {
          return [v[0], v[1].join(",")];
        }

        return v;
      })
  );

  return {
    pathname: location.pathname,
    search: queryString.stringify(paramsWithoutNullOrEmptyValues),
  };
};

const GroupSidebar = ({ group, ...args }: { group: any }) => {
  return (
    <Box
      maxWidth="268px"
      style={{
        flex: "1",
      }}
    >
      <Sidebar {...args}>
        <SidebarPrimaryNavLink to={`/group/${group.id}/settings`}>
          Settings
        </SidebarPrimaryNavLink>
        <SidebarItemGroup label="Users">
          <SidebarNavLink to={`/group/${group.id}/users`}>
            All users
          </SidebarNavLink>
          <SidebarNavLink to={`/group/${group.id}/active-users`}>
            Active users
          </SidebarNavLink>
          <SidebarNavLink to={`/group/${group.id}/disabled-users`}>
            Disabled users
          </SidebarNavLink>
          <SidebarNavLink to={`/group/${group.id}/facilitators`}>
            Facilitators
          </SidebarNavLink>
          <SidebarNavLink to={`/group/${group.id}/respondents`}>
            Respondents
          </SidebarNavLink>
        </SidebarItemGroup>
      </Sidebar>
    </Box>
  );
};
export const IndexWrap = ({
  children,
  rhsChildren,
  headerChildren,
  organizationName,
  organizationId,
  group,
}: any) => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <MaxWidthContainer
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Flex flexDirection="column">
            <PageHeading>
              {/* <GroupName /> */}
              {headerChildren}
            </PageHeading>
            <Link to={`/organization/${organizationId}`}>
              {/* <GroupName /> */}
              {organizationName}
            </Link>
          </Flex>
          <Flex ml="4">{rhsChildren}</Flex>
        </MaxWidthContainer>
        <MaxWidthContainer
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <GroupSidebar group={group} />
          {children}
        </MaxWidthContainer>
      </Flex>
    </>
  );
};

export const GroupDashboardPage = ({ match }: any) => {
  const [showAddBulkUsersModal, setshowAddBulkUsersModal] = useState({
    enabled: false,
  });

  const params: {
    groupId: string;
  } = useParams();

  const { loading, data, error } = useQuery<GetGroup>(GET_GROUP, {
    variables: {
      id: params.groupId,
    },
  });

  if (!data?.group) {
    return <PageLoader />;
  }

  const group = data.group;

  return (
    <>
      <IndexWrap
        group={group}
        headerChildren={group.name}
        organizationName={group.organization.name}
        organizationId={group.organization.id}
        rhsChildren={
          <>
            <Button
              mr="2"
              variant="brand"
              onClick={() =>
                // setShowCreateUserModal({
                //   enabled: true,
                // })
                null
              }
              disabled
            >
              Add Users
            </Button>
            <Button
              variant="brand"
              onClick={() =>
                // setShowCreateUserModal({
                //   enabled: true,
                // })
                null
              }
              disabled
            >
              Remove Users
            </Button>
          </>
        }
      >
        <Flex flexDirection="column" flex="1">
          <Switch>
            <Redirect
              exact
              from={`${match.path}/`}
              to={`${match.path}/settings`}
            />

            <Route
              path={`${match.path}/settings`}
              render={() => <GroupSettings groupId={group.id} />}
            />

            <Route
              path={`${match.path}/users`}
              render={() => (
                <GroupUsers groupId={group.id} csvPrefix="all-users" />
              )}
            />
            <Route
              path={`${match.path}/facilitators`}
              render={() => (
                <GroupUsers
                  groupId={group.id}
                  csvPrefix="facilitators"
                  accountTypeNotIn={["USER"]}
                />
              )}
            />
            <Route
              path={`${match.path}/respondents`}
              render={() => (
                <GroupUsers
                  groupId={group.id}
                  accountTypeIn={["USER"]}
                  csvPrefix="respondents"
                />
              )}
            />
            <Route
              path={`${match.path}/active-users`}
              render={() => (
                <GroupUsers
                  groupId={group.id}
                  userStatus="ACTIVE"
                  csvPrefix="active-users"
                />
              )}
            />
            <Route
              path={`${match.path}/disabled-users`}
              render={() => (
                <GroupUsers
                  groupId={group.id}
                  userStatus="DISABLED"
                  csvPrefix="disabled-users"
                />
              )}
            />
          </Switch>
        </Flex>
      </IndexWrap>
      {showAddBulkUsersModal.enabled && (
        <AddBulkUsersModal
          isOpen={showAddBulkUsersModal.enabled}
          title={"Titl"}
          importQuery={""}
          preludeText={"prelude text"}
          confirmationTextAddendum={
            "They will not receive any sign up email, but they will be able to immediately access their accounts at check-in.skodel.com."
          }
          onClose={() =>
            setshowAddBulkUsersModal({
              enabled: false,
            })
          }
        />
      )}
    </>
  );
};

export default GroupDashboardPage;
