import {
  CheckInRenderQueryContext,
  BaseQueryContext,
  SelectQueryContext,
  NameQueryContext,
} from "@skodel/sk-ui";
import React from "react";

import {
  GetViewerIdForComments,
  GetCommentsForCheckIn,
  GetMyReactionsForCheckIn,
  GetConcernsForCheckIn,
  GetMyConcernDetailsForOrganization,
  GetCheckInDescriptionData,
  GetTagsForCheckIn,
  CommentOnCheckIn,
  DeleteCommentFromCheckIn,
  ReactToComment,
  DeleteReaction,
  ReactToCheckIn,
} from "./queries";
import {
  GetAccountsByName,
  GetCheckInStylesByName,
  GetCountriesByName,
  GetFacilitatorsByName,
  GetGroupsByName,
  GetLanguagesByName,
  GetOrganizationsByName,
  GetRespondentsByName,
  GetRespondentsGroupsSearch,
} from "./queries";
import {
  GetCheckInCollectionName,
  GetCheckInStyleName,
  GetGroupName,
  GetOrganizationName,
  GetScheduledCheckInName,
  GetUserName,
} from "./queries";
import {
  GetBrandLogo,
  GetUnreadNotificationCount,
  GetUserAccountImpersonation,
  GetViewerNotificationsForDropdown,
  AcknowledgeNotifications,
  InteractWithNotification,
  RecordPageView,
  StopImpersonating,
  UpdateLanguage,
} from "./queries";

export const CheckInRenderProvider = (props: any) => {
  return (
    <CheckInRenderQueryContext.Provider
      value={{
        GetViewerIdForComments,
        GetCommentsForCheckIn,
        GetMyReactionsForCheckIn,
        GetConcernsForCheckIn,
        GetMyConcernDetailsForOrganization,
        GetCheckInDescriptionData,
        GetTagsForCheckIn,

        CommentOnCheckIn,
        DeleteCommentFromCheckIn,
        ReactToComment,
        DeleteReaction,
        ReactToCheckIn,
      }}
      {...props}
    />
  );
};

export const BaseQueryContextProvider = (props: any) => {
  return (
    <BaseQueryContext.Provider
      value={{
        GetBrandLogo,
        GetUnreadNotificationCount,
        GetUserAccountImpersonation,
        GetViewerNotificationsForDropdown,
        AcknowledgeNotifications,
        InteractWithNotification,

        RecordPageView,
        StopImpersonating,
        UpdateLanguage,
      }}
      {...props}
    />
  );
};

export const SelectQueryContextProvider = (props: any) => {
  return (
    <SelectQueryContext.Provider
      value={{
        GetAccountsByName,
        GetCheckInStylesByName,
        GetCountriesByName,
        GetFacilitatorsByName,
        GetGroupsByName,
        GetLanguagesByName,
        GetOrganizationsByName,
        GetRespondentsByName,
        GetRespondentsGroupsSearch,
      }}
      {...props}
    />
  );
};

export const NameQueryContextProvider = (props: any) => {
  return (
    <NameQueryContext.Provider
      value={{
        GetCheckInCollectionName,
        GetCheckInStyleName,
        GetGroupName,
        GetOrganizationName,
        GetScheduledCheckInName,
        GetUserName,
      }}
      {...props}
    />
  );
};

export const QueryProviders = (props: any) => {
  return (
    <BaseQueryContextProvider>
      <NameQueryContextProvider>
        <SelectQueryContextProvider>
          <CheckInRenderProvider {...props} />
        </SelectQueryContextProvider>
      </NameQueryContextProvider>
    </BaseQueryContextProvider>
  );
};
