import { useMutation } from "@apollo/client";
import {
  Modal,
  ModalCloseButton,
  Button,
  Text,
  Box,
  TextInput,
  FormLabel,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";

const UpdateUser = loader("./UpdateUser.graphql");

const options = [
  {
    label: "Wellbeing Leader",
    value: "WELLBEING_LEADER",
  },
  {
    label: "Wellbeing Facilitator",
    value: "WELLBEING_FACILITATOR",
  },
  {
    label: "Organization Manager",
    value: "ORG_MANAGER",
  },
  {
    label: "Distributor",
    value: "DISTRIBUTOR",
  },
  {
    label: "Global Administrator",
    value: "GLOBAL_ADMINISTRATOR",
    isDisabled: true,
  },
];

const optionsByValue = {
  WELLBEING_LEADER: {
    label: "Wellbeing Leader",
    value: "WELLBEING_LEADER",
  },
  WELLBEING_FACILITATOR: {
    label: "Wellbeing Facilitator",
    value: "WELLBEING_FACILITATOR",
  },
  ORG_MANAGER: {
    label: "Organization Manager",
    value: "ORG_MANAGER",
  },
  DISTRIBUTOR: {
    label: "Distributor",
    value: "DISTRIBUTOR",
  },
  GLOBAL_ADMINISTRATOR: {
    label: "Global Administrator",
    value: "GLOBAL_ADMINISTRATOR",
  },
};

const EditUserModal = ({
  isOpen,
  onClose,

  user,
  ...otherProps
}: any) => {
  const [formState, setFormState]: any = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    // @ts-ignore
    accountType: optionsByValue[user.accountType],
    hasEmail: !!user.email,
  });

  const [updateUser, state] = useMutation(UpdateUser);

  const shouldInputBeDisabled =
    formState.firstName.length === 0 ||
    formState.lastName.length === 0 ||
    (formState.email || "").length === 0 ||
    formState.email.indexOf("@") === -1;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Editing User"
    >
      <Modal.Header>
        <Text>
          Editing {user.firstName} {user.lastName}
        </Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="firstName">
          First Name
        </FormLabel>
        <TextInput
          id="firstName"
          name="firstName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
          value={formState.firstName}
        />

        <FormLabel p={2} px={0} htmlFor="lastName">
          Last Name
        </FormLabel>
        <TextInput
          id="lastName"
          name="lastName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
          value={formState.lastName}
        />
        {formState.hasEmail && (
          <>
            <FormLabel p={2} px={0} htmlFor="email">
              Email
            </FormLabel>
            <TextInput
              id="email"
              name="email"
              onChange={(e: any) =>
                setFormState({
                  ...formState,
                  email: e.target.value,
                })
              }
              value={formState.email}
            />
          </>
        )}
        <FormLabel p={2} px={0} htmlFor="accountType">
          Account Type
        </FormLabel>
        <Box>
          <Select
            id="account-type"
            name="account-type"
            value={formState.accountType}
            isDisabled={
              formState.accountType &&
              (formState.accountType.value === "GLOBAL_ADMINISTRATOR" ||
                formState.accountType.value === "USER")
            }
            onChange={(newValue: any) =>
              setFormState({
                ...formState,
                accountType: newValue,
              })
            }
            options={options}
          />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled || state.loading}
          onClick={() => {
            updateUser({
              variables: {
                id: user.id,
                ...(formState.firstName === user.firstName
                  ? {}
                  : { firstName: formState.firstName }),
                ...(formState.lastName === user.lastName
                  ? {}
                  : { lastName: formState.lastName }),
                ...(formState.email === user.email
                  ? {}
                  : { email: formState.email }),
                ...(formState.accountType === user.accountType
                  ? {}
                  : { accountType: formState.accountType.value }),
              },
            })
              .then((response: any) => {
                if (response.data.updateUser.success) {
                  toast.success("Successfully updated user.");
                  onClose();
                } else {
                  if (
                    response.data.updateUser.__typename === "NoSuchObjectError"
                  ) {
                    toast.error(response.data.updateUser.message);
                  } else if (
                    response.data.updateUser.__typename === "OperationForbidden"
                  ) {
                    toast.error(response.data.updateUser.message);
                  } else if (
                    response.data.updateUser.__typename === "ValidationError"
                  ) {
                    toast.error(response.data.updateUser.message);
                  } else if (
                    response.data.updateUser.__typename ===
                    "OperationNotPermitted"
                  ) {
                    toast.error(response.data.updateUser.message);
                  }
                }
              })
              .catch((err: any) => {
                toast.error(
                  "Could not update user. Please check your internet connection and try again."
                );
              });
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default EditUserModal;
