import { loader } from "graphql.macro";

export const GetAccountsByName = loader("./GetAccountsByName.graphql");
export const GetCheckInStylesByName = loader(
  "./GetCheckInStylesByName.graphql"
);
export const GetCountriesByName = loader("./GetCountriesByName.graphql");
export const GetFacilitatorsByName = loader("./GetFacilitatorsByName.graphql");
export const GetGroupsByName = loader("./GetGroupsByName.graphql");
export const GetLanguagesByName = loader("./GetLanguagesByName.graphql");
export const GetOrganizationsByName = loader(
  "./GetOrganizationsByName.graphql"
);
export const GetRespondentsByName = loader("./GetRespondentsByName.graphql");
export const GetRespondentsGroupsSearch = loader(
  "./GetRespondentsGroupsSearch.graphql"
);
