import { loader } from "graphql.macro";

export const GetCheckInCollectionName = loader(
  "./GetCheckInCollectionName.graphql"
);
export const GetCheckInStyleName = loader("./GetCheckInStyleName.graphql");
export const GetGroupName = loader("./GetGroupName.graphql");
export const GetOrganizationName = loader("./GetOrganizationName.graphql");
export const GetScheduledCheckInName = loader(
  "./GetScheduledCheckInName.graphql"
);
export const GetUserName = loader("./GetUserName.graphql");
