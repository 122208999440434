import { Label, Table, UserAccountType, Flex, Text } from "@skodel/sk-ui";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import DeleteUserModal from "../../modals/DeleteUserModal";
import EditUserModal from "../../modals/EditUserModal";
import SetPasswordForUserModal from "../../modals/SetPasswordForUser";
import SortControl from "../sort-control";
import UserActions from "./UserActions";

export const UserTable = ({
  users,
  setCurrentSort,
  sort,
  sortDirection,
}: {
  sort?: any;
  sortDirection?: any;
  setCurrentSort?: any;
  users: {
    id: string;
    firstName: string;
    lastName: string;
    accountType: string;
    organization: {
      id: string;
      name: string;
    };
  }[];
}) => {
  const [showDeleteUserModal, setShowDeleteUserModal]: any = useState({
    enabled: false,
  });

  const [showEditUserModal, setShowEditUserModal]: any = useState({
    enabled: false,
  });

  const [showSetPasswordForUserModal, setShowSetPasswordForUserModal]: any =
    useState({});

  return (
    <>
      <Table.Base>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderData
              style={{
                width: "15%",
              }}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Text>Name</Text>
                <SortControl
                  sort={sort}
                  sortDirection={sortDirection}
                  columnId={"FULL_NAME"}
                  setCurrentSort={setCurrentSort}
                />
              </Flex>
            </Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "20%",
              }}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Text>Email</Text>
                <SortControl
                  sort={sort}
                  sortDirection={sortDirection}
                  columnId={"EMAIL"}
                  setCurrentSort={setCurrentSort}
                />
              </Flex>
            </Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "15%",
              }}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Text>Account Type</Text>
                <SortControl
                  sort={sort}
                  sortDirection={sortDirection}
                  columnId={"ACCOUNT_TYPE"}
                  setCurrentSort={setCurrentSort}
                />
              </Flex>
            </Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "30%",
              }}
            ></Table.HeaderData>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {users.map((user: any, idx: any) => (
            <Table.BodyRow key={idx}>
              <Table.BodyData>
                <Link to={`/user/${user.id}`}>
                  {user.firstName}&nbsp;{user.lastName}
                </Link>
              </Table.BodyData>
              <Table.BodyData>
                <Label>{user.email}</Label>
              </Table.BodyData>
              {/* <Table.BodyData>{user.organization.name}</Table.BodyData> */}
              <Table.BodyData>
                <Label>
                  <UserAccountType accountType={user.accountType} />
                </Label>
              </Table.BodyData>
              <Table.BodyData>
                <Flex width="100%" flexDirection="column" alignItems="flex-end">
                  <UserActions
                    user={user}
                    setShowDeleteUserModal={setShowDeleteUserModal}
                    setShowEditUserModal={setShowEditUserModal}
                    setShowSetPasswordForUserModal={
                      setShowSetPasswordForUserModal
                    }
                  />
                </Flex>
              </Table.BodyData>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table.Base>
      {showDeleteUserModal.enabled && (
        <DeleteUserModal
          isOpen={showDeleteUserModal.enabled}
          callback={() => {
            console.log("deleting user");
          }}
          user={showDeleteUserModal.user}
          onClose={() => {
            setShowDeleteUserModal({
              enabled: false,
            });
          }}
        />
      )}

      {showSetPasswordForUserModal.enabled && (
        <SetPasswordForUserModal
          isOpen={showSetPasswordForUserModal.enabled}
          user={showSetPasswordForUserModal.user}
          onClose={() => {
            setShowSetPasswordForUserModal({
              enabled: false,
            });
          }}
        />
      )}

      {showEditUserModal.enabled && (
        <EditUserModal
          isOpen={showEditUserModal.enabled}
          callback={() => {
            console.log("saving new profile for user");
          }}
          user={showEditUserModal.user}
          onClose={() => {
            setShowEditUserModal({
              enabled: false,
            });
          }}
        />
      )}
    </>
  );
};
