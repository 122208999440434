import { useQuery } from "@apollo/react-hooks";
import { Flex } from "@skodel/sk-ui";
import { Pagination, getFeedParams, getNewLocation } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useLocation } from "react-router";

import { UserTable } from "../components/user-table";
import { IndexWrap } from "../organizations-dashboard";

const GET_USERS = loader("./GetUsers.graphql");

export const UserAccountType = ({ accountType }: any) => {
  if (accountType === "USER") {
    return <>Student</>;
  }

  if (accountType === "WELLBEING_LEADER") {
    return <>Wellbeing Leader</>;
  }

  if (accountType === "WELLBEING_FACILITATOR") {
    return <>Wellbeing Facilitator</>;
  }

  if (accountType === "ORG_MANAGER") {
    return <>Organization Manager</>;
  }

  if (accountType === "DISTRIBUTOR") {
    return <>Distributor</>;
  }

  if (accountType === "GLOBAL_ADMINISTRATOR") {
    return <>Global Administrator</>;
  }

  return <>Unknown</>;
};

export const UsersDashboard = ({ history }: any) => {
  const [showCreateFacilitatorModal, setShowCreateFacilitatorModal]: any =
    useState({
      enabled: false,
    });

  const [showCreateLeaderModal, setShowCreateLeaderModal]: any = useState({
    enabled: false,
  });

  const [showCreateOrgManagerModal, setShowCreateOrgManagerModal]: any =
    useState({
      enabled: false,
    });

  const location = useLocation();
  const feedParams = getFeedParams(location);

  const { loading, data, error } = useQuery(GET_USERS, {
    variables: {
      ...feedParams,
    },
  });

  return (
    <IndexWrap
      history={history}
      location={location}
      flexDirection="column"
      rhsChildren={null}
    >
      <Flex
        pt="3"
        justifyContent="stretch"
        alignItems="stretch"
        flex="1"
        flexDirection="column"
      >
        <UserTable
          users={(data?.users?.edges || []).map((edge: any) => edge.node)}
        />

        <Flex flex="1" justifyContent="flex-end" px={3}>
          {data && data.users.pageInfo && feedParams && getNewLocation && (
            <Pagination
              pageInfo={data.users.pageInfo}
              feedParams={feedParams}
              getNewLocation={getNewLocation}
            />
          )}
        </Flex>
      </Flex>
    </IndexWrap>
  );
};

export default UsersDashboard;
