import { useMutation } from "@apollo/react-hooks";
import {
  Text,
  Flex,
  ModalCloseButton,
  Button,
  Modal,
  PasswordInput,
  FormLabel,
} from "@skodel/sk-ui";
import { Eye } from "@styled-icons/bootstrap/Eye";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import generator from "generate-password";
import { loader } from "graphql.macro";
// @ts-ignore
import { PasswordPolicy, charsets } from "password-sheriff";
import React, { useState } from "react";
import { toast } from "react-toastify";

const SetPasswordForUser = loader("./SetPasswordForUser.graphql");

const lengthPolicy = new PasswordPolicy({
  length: {
    minLength: 8,
  },
  contains: {
    expressions: [
      charsets.lowerCase,
      charsets.upperCase,
      charsets.numbers,
      charsets.specialCharacters,
    ],
  },
});

const PasswordGeneratorOptions = {
  length: 8,
  numbers: true,
  symbols: true,
  lowercase: true,
  uppercase: true,
  excludeSimilarCharacters: true,
  strict: true,
};

const SetPasswordForUserModal = ({
  isOpen,
  onClose,
  user,
  ...otherProps
}: {
  isOpen: boolean;
  onClose: () => void;
  user: {
    id: string;
  };
}) => {
  const [setPassword, setPasswordState] = useMutation(SetPasswordForUser);
  const [showPassword, setShowPassword] = useState(false);
  const [formState, setFormState]: any = useState({
    password: "",
  });

  const passwordValid =
    formState.password.length > 0 && lengthPolicy.check(formState.password);

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Set Password for User"
    >
      <Modal.Header>
        <Text>Set Password for User</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <Text color="grey">
          Our users security is our priority: the password must contain at least
          8 characters, a lowercase letter, uppercase letter, a number and a
          symbol
        </Text>
        <Flex my="2" alignItems="center">
          <FormLabel px={0} htmlFor="password">
            Password
          </FormLabel>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              minWidth: "unset",
            }}
            p="2"
            py="1"
            onClick={() => {
              setShowPassword(true);
              setFormState({
                password: generator.generate(PasswordGeneratorOptions),
              });
            }}
          >
            <EyeFill width="0.8em" height="0.8em" color="white" />
            <Text ml="2" fontSize="0.8em">
              Generate
            </Text>
          </Button>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <PasswordInput
            id="password"
            name="password"
            type={showPassword ? "text" : "password"}
            onChange={(e: any) =>
              setFormState({
                ...formState,
                password: e.target.value,
              })
            }
            style={{
              flex: "1",
            }}
            value={formState.password}
          />
          <Button
            ml="2"
            style={{
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {!showPassword ? (
              <>
                <EyeFill width="0.8em" height="0.8em" color="white" />
                <Text ml="2" fontSize="0.8em">
                  Show
                </Text>
              </>
            ) : (
              <>
                <Eye width="0.8em" height="0.8em" color="white" />
                <Text ml="2" fontSize="0.8em">
                  Hide
                </Text>
              </>
            )}
          </Button>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!passwordValid || setPasswordState.loading}
          onClick={() => {
            setPassword({
              variables: {
                userId: user.id,
                password: formState.password,
              },

              refetchQueries: ["GetUsers"],
            })
              .then((response: any) => {
                if (response.data.setPassword.success) {
                  toast.success("Successfully set password for user.");
                } else {
                  if (response.data.setPassword.message) {
                    toast.error(response.data.setPassword.message);
                  } else {
                    toast.error(
                      "Could not set a password for this user. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not set a password for this user. Please check your internet connection and try again."
                )
              );
          }}
        >
          Set Password
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default SetPasswordForUserModal;
