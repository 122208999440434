import { Box } from "@skodel/sk-ui";
import React, { useReducer } from "react";
import { toast } from "react-toastify";

import { ImportPage } from "./ImportPage";
import { SelectColumnsPage } from "./SelectColumnsPage";
import { SelectPage } from "./SelectPage";
import { UploadPage } from "./UploadPage";

export const MAX_COLUMNS = 5;
export const MAX_ROWS = 4;

const initialState: ImportState = { step: "UPLOAD" };

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// TODO: dedupe?

const getInvalidDataIdx = (state: ImportStateImport, rowMap: any) => {
  return state.data
    .map((_: any, rowIdx: number) => rowIdx)
    .filter((rowIdx: number) => {
      if (rowIdx < state.firstRowIdx) {
        return true;
      }

      const row = state.data[rowIdx];

      if (row.length < Math.max.apply(null, Object.values(rowMap))) {
        return true;
      }

      if ((row[rowMap["first name"]] || "").trim().length === 0) {
        return true;
      }

      if ((row[rowMap["last name"]] || "").trim().length === 0) {
        return true;
      }
      if ((row[rowMap["email"]] || "").trim().length === 0) {
        return true;
      }

      return !emailRegex.test(row[rowMap["email"]]);
    });
};

type ImportStateAction =
  | {
      type: "OPEN_SELECT_ROW";
      data: any[];
    }
  | {
      type: "OPEN_SELECT_COLUMNS";
      firstRowIdx: number;
    }
  | {
      type: "OPEN_IMPORT";
      rowMap: any[];
      invalidDataIdx: number[];
    };

type ImportStateImport = {
  step: "IMPORT";
  data: any[];
  firstRowIdx: number;
  rowMap: any;
  invalidDataIdx: number[];
};

type ImportState =
  | {
      step: "UPLOAD";
    }
  | {
      step: "SELECT_ROW";
      data: any[];
    }
  | {
      step: "SELECT_COLUMNS";
      data: any[];
      firstRowIdx: number;
    }
  | ImportStateImport;

function reducer(state: ImportState, action: ImportStateAction) {
  switch (action.type) {
    case "OPEN_SELECT_ROW":
      return {
        step: "SELECT_ROW",
        data: action.data,
      };
    case "OPEN_SELECT_COLUMNS":
      return {
        ...state,
        step: "SELECT_COLUMNS",
        firstRowIdx: action.firstRowIdx,
      };
    case "OPEN_IMPORT":
      return {
        ...state,
        step: "IMPORT",
        rowMap: action.rowMap,
        invalidDataIdx: action.invalidDataIdx,
      };
    default:
      throw new Error();
  }
}

export const Container = ({
  onClose,
  title,
  preludeText,
  confirmationTextAddendum,
  importQuery,
}: any) => {
  // @ts-ignore
  const [state, dispatch]: any = useReducer(reducer, initialState);

  // upload, select, import
  return (
    <Box p="3" pt="2">
      {state.step === "UPLOAD" && (
        <UploadPage
          onClose={onClose}
          title={title}
          preludeText={preludeText}
          setDataAndIncrementStep={(data: any) => {
            dispatch({
              type: "OPEN_SELECT_ROW",
              data,
            });
          }}
        />
      )}
      {state.step === "SELECT_ROW" && (
        <SelectPage
          onClose={onClose}
          state={state}
          title={title}
          setDataAndIncrementStep={(firstRowIdx: number) => {
            dispatch({
              type: "OPEN_SELECT_COLUMNS",
              firstRowIdx,
            });
          }}
        />
      )}
      {state.step === "SELECT_COLUMNS" && (
        <SelectColumnsPage
          onClose={onClose}
          state={state}
          title={title}
          setDataAndIncrementStep={(rowMap: any) => {
            const invalidDataIdx = getInvalidDataIdx(state, rowMap);
            // @ts-ignore
            if (invalidDataIdx.length === state.data.length) {
              toast.error(
                "This CSV file has no valid entries. Please have a look at your formatting and try again."
              );
              onClose();
            } else {
              dispatch({
                type: "OPEN_IMPORT",
                rowMap,
                invalidDataIdx,
              });
            }
          }}
        />
      )}
      {state.step === "IMPORT" && (
        <ImportPage
          onClose={onClose}
          state={state}
          importQuery={importQuery}
          title={title}
          confirmationTextAddendum={confirmationTextAddendum}
          setDataAndIncrementStep={() => {
            alert("finished!");
          }}
        />
      )}
    </Box>
  );
};
