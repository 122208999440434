import {
  useHasAuthenticatedSuccessfully,
  useHasToken,
  GraphiQLView,
} from "@skodel/sk-ui";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";

import { PageWrapper } from "./PageWrapper";
import GroupDashboardPage from "./group-dashboard";
import OrganizationDashboardPage from "./organization-dashboard";
import OrganizationsDashboard from "./organizations-dashboard";
import UserDashboardPage from "./user-dashboard";
import UsersDashboard from "./users-dashboard";

const AppRouter = () => {
  const hasAuthenticatedSuccessfully = useHasAuthenticatedSuccessfully();
  const hasToken = useHasToken();

  if (!hasToken) {
    return <Redirect to="/login" />;
  }

  return (
    <PageWrapper>
      <Switch>
        {hasAuthenticatedSuccessfully === false && (
          <Redirect exact to="/login" />
        )}
        <Route exact path="/organizations" component={OrganizationsDashboard} />
        <Route
          path="/organization/:organizationId"
          component={OrganizationDashboardPage}
        />
        <Route path="/group/:groupId" component={GroupDashboardPage} />
        <Route path="/user/:userId" component={UserDashboardPage} />
        <Route exact path="/users" component={UsersDashboard} />
        <Route path="/graphiql" component={GraphiQLView} />
        <Redirect exact from="/" to="/organizations" />
      </Switch>
    </PageWrapper>
  );
};

export default AppRouter;
