import { useMutation } from "@apollo/client";
import { Box, Text, Table, Button } from "@skodel/sk-ui";
import { Check } from "@styled-icons/boxicons-regular/Check";
import { Cross } from "@styled-icons/icomoon/Cross";
import FileSaver from "file-saver";
import { loader } from "graphql.macro";
import Papa from "papaparse";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BaseModalContent } from "./BaseModal";
import FlowHeading from "./FlowHeading";

const BulkAddUsers = loader("./BulkAddUsersToOrganization.graphql");

export const ImportPage = ({
  state,
  onClose,
  title,
  confirmationTextAddendum,
}: any) => {
  const { t } = useTranslation();

  const [bulkAddUsers, bulkAddUsersData] = useMutation(BulkAddUsers);
  const [deselectedIdxs, setDeselectedIdxs] = useState<number[]>([]);
  const total = state.data.length;
  const totalInvalid = state.invalidDataIdx.length;
  const totalSelected = total - totalInvalid - deselectedIdxs.length;

  return (
    <BaseModalContent
      title={title}
      onClose={onClose}
      body={
        <>
          {totalInvalid - state.firstRowIdx > 0 && (
            <Box
              bg="rgb(238,244,248)"
              mb="3"
              style={{
                border: "1px solid rgb(189, 219, 239)",
                borderRadius: "4px",
                padding: "16px",
              }}
            >
              <Text>
                <Trans
                  t={t}
                  i18nKey="rows_could_not_be_imported_due_to_empty_or"
                >
                  {{
                    invalidCount: totalInvalid - state.firstRowIdx,
                  }}{" "}
                  / {{ total: total - state.firstRowIdx }} rows could not be
                  imported due to empty or invalid column data. You can download
                  these rows in CSV format
                  <Button
                    variant="linkSmall"
                    style={{
                      paddingLeft: "2px",
                      paddingRight: "2px",
                    }}
                    onClick={() => {
                      const outputCsv = Papa.unparse(
                        state.invalidDataIdx
                          .filter(
                            (rowIdx: number) => rowIdx >= state.firstRowIdx
                          )
                          .map((rowIdx: number) => {
                            return state.data[rowIdx];
                          })
                      );

                      const blob = new Blob([outputCsv], {
                        type: "text/csv;charset=utf-8",
                      });

                      FileSaver.saveAs(
                        blob,
                        `skodel-invalid-rows-${new Date().getTime()}.csv`
                      );
                    }}
                  >
                    {" "}
                    here.
                  </Button>
                </Trans>
              </Text>
            </Box>
          )}
          <FlowHeading mt="2">
            {t("BulkImport.confirm", { defaultValue: "Confirm" })}
          </FlowHeading>
          <Text color="grey" my="3">
            <Trans
              t={t}
              i18nKey="BulkImport.by_clicking_finish_you_will_add_the_"
            >
              By clicking Finish, you will add the {{ selected: totalSelected }}{" "}
              selected users below to your Skodel organization.{" "}
              {{
                confirmationTextAddendum: confirmationTextAddendum,
              }}
            </Trans>
          </Text>
          <Box
            style={{
              overflowY: "scroll",
              maxHeight: "300px",
            }}
          >
            <Table.Base>
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderData>
                    {t("BulkImport.first_name", {
                      defaultValue: "First Name",
                    })}
                  </Table.HeaderData>
                  <Table.HeaderData>
                    {t("BulkImport.last_name", {
                      defaultValue: "Last Name",
                    })}
                  </Table.HeaderData>
                  <Table.HeaderData>
                    {t("BulkImport.email", {
                      defaultValue: "Email",
                    })}
                  </Table.HeaderData>
                  <Table.HeaderData>
                    {t("BulkImport.should_import", {
                      defaultValue: "Should Import?",
                    })}
                  </Table.HeaderData>
                </Table.HeaderRow>
              </Table.Header>
              <Table.Body>
                {
                  // @ts-ignore
                  state.data
                    .slice(state.firstRowIdx, state.data.length)
                    .map((row: any, rowIdx: any) => {
                      rowIdx = rowIdx + state.firstRowIdx;
                      if (state.invalidDataIdx.includes(rowIdx)) {
                        return null;
                      }
                      const isSelected = !deselectedIdxs.includes(rowIdx);
                      return (
                        <Table.BodyRow key={rowIdx}>
                          <Table.BodyData
                            style={{
                              color: !isSelected ? "grey" : "inherit",
                            }}
                          >
                            {row[state.rowMap["first name"]]}
                          </Table.BodyData>
                          <Table.BodyData
                            style={{
                              color: !isSelected ? "grey" : "inherit",
                            }}
                          >
                            {row[state.rowMap["last name"]]}
                          </Table.BodyData>
                          <Table.BodyData
                            style={{
                              color: !isSelected ? "grey" : "inherit",
                            }}
                          >
                            {row[state.rowMap["email"]]}
                          </Table.BodyData>
                          <Table.BodyData>
                            {!isSelected ? (
                              <Button
                                variant="link"
                                onClick={() => {
                                  setDeselectedIdxs(
                                    deselectedIdxs.filter(
                                      (idx: number) => idx !== rowIdx
                                    )
                                  );
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box mr="2">
                                  <Cross
                                    width="15px"
                                    height="15px"
                                    color="red"
                                  />
                                </Box>
                                {t("BulkImport.no", {
                                  defaultValue: "No",
                                })}
                              </Button>
                            ) : (
                              <Button
                                variant="link"
                                onClick={() => {
                                  setDeselectedIdxs([
                                    ...deselectedIdxs,
                                    rowIdx,
                                  ]);
                                }}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box mr="2">
                                  <Check
                                    width="18px"
                                    height="18px"
                                    color="green"
                                  />
                                </Box>
                                {t("BulkImport.yes", {
                                  defaultValue: "Yes",
                                })}
                              </Button>
                            )}
                          </Table.BodyData>
                        </Table.BodyRow>
                      );
                    })
                }
              </Table.Body>
            </Table.Base>
          </Box>
        </>
      }
      footer={
        <Button
          variant="brand"
          disabled={totalSelected === 0 || bulkAddUsersData.loading}
          onClick={async () => {
            const usersToImport = state.data
              .slice(state.firstRowIdx, state.data.length)
              .filter((row: any, rowIdx: number) => {
                rowIdx = rowIdx + state.firstRowIdx;

                if (state.invalidDataIdx.includes(rowIdx)) {
                  return false;
                }

                return !deselectedIdxs.includes(rowIdx);
              })
              .map((row: any) => {
                return {
                  firstName: row[state.rowMap["first name"]],
                  lastName: row[state.rowMap["last name"]],
                  email: row[state.rowMap["email"]],
                  accountType: "USER",
                };
              });

            try {
              const { data } = await bulkAddUsers({
                variables: {
                  input: {
                    users: usersToImport,
                  },
                },
              });

              if (!data?.bulkAddUsersToOrganization?.success) {
                if (data?.bulkAddUsersToOrganization?.message) {
                  toast.error(data?.bulkAddUsersToOrganization?.message);
                }
              } else {
                toast.success("Successfully imported users.");
                onClose();
              }
            } catch (err: any) {
              console.error(err);
              toast.error(
                "Could not import users. Please check your internet connection and try again."
              );
            }
          }}
        >
          {t("BulkImport.finish", { defaultValue: "Finish" })}
        </Button>
      }
    />
  );
};
