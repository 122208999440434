import { loader } from "graphql.macro";

export const GetViewerIdForComments = loader(
  "./GetViewerIdForComments.graphql"
);
export const GetCommentsForCheckIn = loader("./GetCommentsForCheckIn.graphql");
export const GetMyReactionsForCheckIn = loader(
  "./GetMyReactionsForCheckIn.graphql"
);
export const GetConcernsForCheckIn = loader("./GetConcernsForCheckIn.graphql");
export const GetMyConcernDetailsForOrganization = loader(
  "./GetMyConcernDetailsForOrganization.graphql"
);
export const GetCheckInDescriptionData = loader(
  "./GetCheckInDescriptionData.graphql"
);
export const GetTagsForCheckIn = loader("./GetTagsForCheckIn.graphql");

export const CommentOnCheckIn = loader("./CommentOnCheckIn.graphql");
export const DeleteCommentFromCheckIn = loader(
  "./DeleteCommentFromCheckIn.graphql"
);
export const ReactToComment = loader("./ReactToComment.graphql");
export const DeleteReaction = loader("./DeleteReaction.graphql");
export const ReactToCheckIn = loader("./ReactToCheckIn.graphql");
