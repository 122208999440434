// import * as Sentry from "@sentry/react";
import {
  setGlobalSessionStorage,
  GlobalSessionStorage,
  setupToast,
  setupReactModal,
} from "@skodel/sk-ui";
import {
  buildProductioni18nInstance, //   buildDevelopmenti18nInstance,
  processIntrospectionQueryResultData,
} from "@skodel/sk-ui";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";

import App from "./App";
import fragmentTypes from "./fragmentTypes.json";
import * as serviceWorker from "./serviceWorker";

const possibleTypes = processIntrospectionQueryResultData(fragmentTypes);
const sessionStorage = new GlobalSessionStorage(
  "account-admin",
  possibleTypes,
  "skodel-",
  {
    clientId: "83aded77-30ad-4b72-bb4f-7832af2b6754", // this may not work
    redirectUri: "https://distributor.skodel.com/",
  },
  {
    clientId:
      "403948996243-fkj59u82gleia56aqbs3p450rdstjiam.apps.googleusercontent.com",
  }
);
sessionStorage.getBaseHost = () => "https://api.skodel.com";
setGlobalSessionStorage(sessionStorage);
setupToast();
setupReactModal();

if (process.env.NODE_ENV === "production") {
  buildProductioni18nInstance({});
} else {
  //   buildDevelopmenti18nInstance({
  //     projectId: "TODO",
  //     apiKey: "TODO",
  //   });
  buildProductioni18nInstance({});
}

if (process.env.NODE_ENV === "production") {
  //   Sentry.init({
  //     dsn: "https://cbbd4d23a6b84aada9c61ebe1628052f@sentry.skodel.dev/3", // TODO: set correct project
  //     // integrations: [new Integrations.BrowserTracing()],
  //     // tracesSampleRate: 0.3, // 20% of traces are sampled for performance monitoring
  //   });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
