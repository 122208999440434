import { Button } from "@skodel/sk-ui";
import { Sort } from "@styled-icons/fa-solid/Sort";
import { SortAmountDown } from "@styled-icons/fa-solid/SortAmountDown";
import { SortAmountUp } from "@styled-icons/fa-solid/SortAmountUp";
import React from "react";

export const SortControl = ({
  sort,
  sortDirection,
  columnId,
  setCurrentSort,
}: {
  sort?: string | null;
  sortDirection?: string | null;
  columnId: string;
  setCurrentSort: (
    newSort: string | null,
    newSortDirection: string | null
  ) => void;
}) => {
  const isActive = sort === columnId;
  if (isActive) {
    if (sortDirection === "DESC") {
      return (
        <Button
          onClick={() => {
            setCurrentSort(null, null);
          }}
          variant="link"
          p={1}
        >
          <SortAmountDown width="1em" height="1em" />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            setCurrentSort(columnId, "DESC");
          }}
          variant="link"
          p={1}
        >
          <SortAmountUp width="1em" height="1em" />
        </Button>
      );
    }
  } else {
    return (
      <Button
        onClick={() => {
          setCurrentSort(columnId, "ASC");
        }}
        variant="link"
        p={1}
      >
        <Sort width="1em" height="1em" />
      </Button>
    );
  }
};

export default SortControl;
