import { Table } from "@skodel/sk-ui";
import React from "react";
import { Link } from "react-router-dom";

// import DeleteGroupModal from "./DeleteGroupModal";
// import EditGroupModal from "./EditGroupModal";

// import GroupActions from "./GroupActions";

export const GroupTable = ({
  groups,
}: {
  groups: {
    id: string;
    name: string;
  }[];
}) => {
  //   const [showDeleteGroupModal, setShowDeleteGroupModal]: any = useState({
  //     enabled: false,
  //   });

  //   const [showEditGroupModal, setShowEditGroupModal]: any = useState({
  //     enabled: false,
  //   });

  return (
    <>
      <Table.Base>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderData>Name</Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "20%",
              }}
            >
              Members
            </Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "20%",
              }}
            >
              Respondents
            </Table.HeaderData>
            <Table.HeaderData
              style={{
                width: "20%",
              }}
            >
              Facilitators
            </Table.HeaderData>
            {/* <Table.HeaderData
              style={{
                width: "15%",
              }}
            >
              Account Type
            </Table.HeaderData> */}
            <Table.HeaderData></Table.HeaderData>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {groups.map((group: any, idx: any) => (
            <Table.BodyRow key={idx}>
              <Table.BodyData>
                <Link to={`/group/${group.id}`}>{group.name}</Link>
              </Table.BodyData>
              <Table.BodyData>{group.members.totalCount}</Table.BodyData>
              <Table.BodyData>{group.respondents.totalCount}</Table.BodyData>
              <Table.BodyData>{group.facilitators.totalCount}</Table.BodyData>
              <Table.BodyData
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <GroupActions
                  group={group}
                  setShowDeleteGroupModal={setShowDeleteGroupModal}
                  setShowEditGroupModal={setShowEditGroupModal}
                /> */}
              </Table.BodyData>
            </Table.BodyRow>
          ))}
        </Table.Body>
      </Table.Base>
      {/* {showDeleteGroupModal.enabled && (
        <DeleteGroupModal
          isOpen={showDeleteGroupModal.enabled}
          callback={() => {
            console.log("deleting group");
          }}
          group={showDeleteGroupModal.group}
          onClose={() => {
            setShowDeleteGroupModal({
              enabled: false,
            });
          }}
        />
      )}

      {showEditGroupModal.enabled && (
        <EditGroupModal
          isOpen={showEditGroupModal.enabled}
          callback={() => {
            console.log("saving new profile for group");
          }}
          group={showEditGroupModal.group}
          onClose={() => {
            setShowEditGroupModal({
              enabled: false,
            });
          }}
        />
      )} */}
    </>
  );
};
