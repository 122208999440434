import { ApolloClient, useApolloClient } from "@apollo/react-hooks";
import { Button, Text } from "@skodel/sk-ui";
import { Download } from "@styled-icons/boxicons-solid/Download";
import FileSaver from "file-saver";
import Papa from "papaparse";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const getPaginatedDataEdges: (
  query: any,
  client: ApolloClient<any>,
  variables: {
    page: number;
  },
  getConnectionFromData: (data: any) => {
    pageInfo: {
      hasNextPage: boolean;
    };
    edges: {
      node: any;
    }[];
  }
) => any = async (query, client, variables, getConnectionFromData) => {
  let queryVariables = variables;
  let { data } = await client.query({
    query,
    variables: queryVariables,
  });

  let connection = getConnectionFromData(data);
  let edges: any[] = connection.edges || [];

  while (connection.pageInfo.hasNextPage) {
    queryVariables = {
      ...queryVariables,
      page: queryVariables.page + 1,
    };

    ({ data } = await client.query({
      query: query,
      variables: queryVariables,
    }));

    connection = getConnectionFromData(data);

    edges = [].concat(edges as any, (connection.edges as any) || ([] as any));
  }

  return edges;
};

export const UserEdgeMapper = (edge: {
  node: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    accountType: string;
  };
}) => ({
  id: edge.node.id,
  firstName: edge.node.firstName,
  lastName: edge.node.lastName,
  email: edge.node.email,
  accountType: edge.node.accountType,
});

export const GroupEdgeMapper = (edge: {
  node: {
    id: string;
    name: string;
    members: {
      totalCount: number;
    };
    respondents: {
      totalCount: number;
    };
    facilitators: {
      totalCount: number;
    };
  };
}) => ({
  name: edge.node.name,
  id: edge.node.id,
  members: edge.node.members.totalCount,
  respondents: edge.node.respondents.totalCount,
  facilitators: edge.node.facilitators.totalCount,
});

export const CsvDownloader = ({
  query,
  variables,
  namePrefix,
  edgeMapper,
  getConnectionFromData,
}: {
  query: any;
  variables: any;
  namePrefix: string;
  edgeMapper: (edge: { node: any }) => any;
  getConnectionFromData: (data: any) => {
    pageInfo: {
      hasNextPage: boolean;
    };
    edges: {
      node: any;
    }[];
  };
}) => {
  const { t } = useTranslation();
  const client = useApolloClient();
  const [isRunning, setIsRunning] = useState(false);

  return (
    <Button
      disabled={isRunning}
      variant="linkSmall"
      fontSize="0.8em"
      onClick={async () => {
        setIsRunning(true);
        try {
          const edges = await getPaginatedDataEdges(
            query,
            client,
            variables as any,
            getConnectionFromData
          );

          const outputCsv = Papa.unparse(edges.map(edgeMapper));

          const blob = new Blob([outputCsv], {
            type: "text/csv;charset=utf-8",
          });

          FileSaver.saveAs(blob, `${namePrefix}-${new Date().getTime()}.csv`);
          setIsRunning(false);
        } catch (e) {
          console.error(e);
          setIsRunning(false);
          toast.error(
            "There was a problem generating the CSV. Please try again and get in contact with Skodel support if the problem persists."
          );
        }
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Download width="1em" height="1em" />
      <Text ml="2">
        {t("CsvDownloader.download_as_csv", {
          defaultValue: "Download as CSV",
        })}{" "}
      </Text>
    </Button>
  );
};

export default CsvDownloader;
