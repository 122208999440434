import { SelectPropsWithOptionalOptions, Select } from "@skodel/sk-ui";
import { keyBy } from "lodash";
import React from "react";

export const AccountTypeSelectOptions = [
  {
    label: "User",
    value: "USER",
  },
  {
    label: "Wellbeing Facilitator",
    value: "WELLBEING_FACILITATOR",
  },
  {
    label: "Wellbeing Leader",
    value: "WELLBEING_LEADER",
  },
  {
    label: "Organization Manager",
    value: "ORG_MANAGER",
  },
  {
    label: "Distributor",
    value: "DISTRIBUTOR",
  },
  {
    label: "Global Administrator",
    value: "GLOBAL_ADMINISTRATOR",
  },
];

const AccountTypeSelectOptionsByKey = keyBy(AccountTypeSelectOptions, "value");

export const AccountTypeSelect: React.FC<SelectPropsWithOptionalOptions> = ({
  onChange,
  value,
  isMulti,
  ...props
}) => {
  let parsedValue = null;

  if (value) {
    if (isMulti) {
      parsedValue = value.map(
        (v: any) => (AccountTypeSelectOptionsByKey as any)[v]
      );
    } else {
      parsedValue = (AccountTypeSelectOptionsByKey as any)[value];
    }
  }

  return (
    <Select
      options={AccountTypeSelectOptions}
      value={parsedValue}
      isMulti={isMulti}
      onChange={(option: any) => {
        if (onChange) {
          if (isMulti) {
            return onChange(option ? option.map((v: any) => v.value) : []);
          } else {
            return onChange(option ? option.value : null);
          }
        }
      }}
      {...props}
    />
  );
};

export default AccountTypeSelect;
