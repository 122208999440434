import { useMutation } from "@apollo/react-hooks";
import { Button } from "@skodel/sk-ui";
import { ArrowDropDown } from "@styled-icons/remix-line/ArrowDropDown";
import { loader } from "graphql.macro";
import React from "react";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";

const RESEND_ACTIVATION_EMAIL = loader("./ResendActivationEmail.graphql");

export const UserActions = ({
  user,
  setShowDeleteUserModal,
  setShowEditUserModal,
  setShowSetPasswordForUserModal,
}: any) => {
  const [resendActivationEmail, resendActivationEmailState]: any = useMutation(
    RESEND_ACTIVATION_EMAIL,
    {
      refetchQueries: ["GetUsers"],
    }
  );

  return (
    <>
      <Dropdown
        trigger={
          <>
            <Button
              variant="link"
              px="2"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              Actions{" "}
              <ArrowDropDown
                width="24px"
                height="24px"
                style={{
                  marginTop: "-2px",
                }}
              />
            </Button>
          </>
        }
      >
        <Dropdown.Menu>
          {!(
            resendActivationEmailState.loading ||
            !(user.canUpdate && !user.hasOnboarded)
          ) && (
            <Dropdown.Item
              text={"Resend Activation Email"}
              onClick={() => {
                resendActivationEmail({
                  variables: {
                    userId: user.id,
                  },
                })
                  .then((data: any) =>
                    toast.success("Successfully sent activation email.")
                  )
                  .catch((err: any) =>
                    toast.error(
                      "Could not send activation email. Please check your internet connection and try again."
                    )
                  );
              }}
            />
          )}
          {user.canUpdate && (
            <Dropdown.Item
              text={"Set Password"}
              onClick={() =>
                setShowSetPasswordForUserModal({
                  enabled: true,
                  user,
                })
              }
            />
          )}
          {user.canUpdate && (
            <Dropdown.Item
              text={"Edit"}
              onClick={() =>
                setShowEditUserModal({
                  enabled: true,
                  user,
                })
              }
            />
          )}

          {user.canDelete && (
            <Dropdown.Item
              text={"Delete"}
              onClick={() =>
                setShowDeleteUserModal({
                  enabled: true,
                  user,
                })
              }
            />
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default UserActions;
