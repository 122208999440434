import { useQuery } from "@apollo/react-hooks";
import { Flex } from "@skodel/sk-ui";
import { VerticalTabs, Tab, Table } from "@skodel/sk-ui";
import {
  Button,
  Pagination,
  getFeedParams,
  getNewLocation,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import CreateOrganizationModal from "../modals/CreateOrganizationModal";
import EditOrganizationModal from "../modals/EditOrganizationModal";

const GET_ORGANIZATIONS = loader("./GetOrganizations.graphql");

export const INDEX_PAGE_TABS: Array<Tab> = [
  {
    id: "ORGANIZATIONS",
    label: "Organizations",
    getUrl: (params) => "/organizations",
  },
  {
    id: "USERS",
    label: "Users",
    getUrl: (params) => "/users",
  },
];

export const IndexWrap = ({
  history,
  location,
  children,
  rhsChildren,
}: any) => {
  const currentTabIdx = INDEX_PAGE_TABS.findIndex(
    (value: Tab, idx) => location.pathname === value.getUrl({})
  );

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex
        flexDirection="row"
        style={{ maxWidth: "1080px", flex: "1", width: "100%" }}
        pt="5"
      >
        <VerticalTabs
          tabs={INDEX_PAGE_TABS}
          isTabActive={(tab: any, tabIdx: number) => {
            return tabIdx === currentTabIdx;
          }}
          changeTab={(tab: any, tabIdx: number) => {
            history.push(tab.getUrl({}));
          }}
        />
        {rhsChildren}
      </Flex>
      <Flex
        flex="1"
        style={{ maxWidth: "1080px", width: "100%", display: "flex" }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export const OrganizationsDashboard = ({ history }: any) => {
  const [showCreateModal, setShowCreateModal]: any = useState({
    enabled: false,
  });
  const [showEditOrganizationModal, setShowEditOrganizationModal]: any =
    useState({
      enabled: false,
    });

  const location = useLocation();
  const feedParams = getFeedParams(location);

  const { loading, data, error } = useQuery(GET_ORGANIZATIONS, {
    variables: {
      ...feedParams,
    },
  });

  return (
    <IndexWrap
      history={history}
      location={location}
      rhsChildren={
        <Flex ml="5">
          <Button
            variant="brand"
            onClick={() =>
              setShowCreateModal({
                enabled: true,
              })
            }
          >
            Create a new organization
          </Button>
        </Flex>
      }
    >
      <Flex
        pt="3"
        justifyContent="stretch"
        alignItems="stretch"
        flex="1"
        flexDirection="column"
      >
        <Table.Base>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderData
                style={{
                  width: "90%",
                }}
              >
                Name
              </Table.HeaderData>
              <Table.HeaderData
                style={{
                  width: "10%",
                }}
              ></Table.HeaderData>
            </Table.HeaderRow>
          </Table.Header>
          <Table.Body>
            {!loading &&
              !error &&
              data.organizations.edges.map((edge: any, idx: any) => (
                <Table.BodyRow key={idx}>
                  <Table.BodyData>
                    <Link to={`/organization/${edge.node.id}`}>
                      {edge.node.name}
                    </Link>
                  </Table.BodyData>
                  <Table.BodyData
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {/* <Button
                      variant="link"
                      px="2"
                      onClick={() =>
                        setShowEditOrganizationModal({
                          enabled: true,
                          organization: edge.node,
                        })
                      }
                    >
                      Edit
                    </Button> */}
                  </Table.BodyData>
                </Table.BodyRow>
              ))}
          </Table.Body>
        </Table.Base>

        {showCreateModal.enabled && (
          <CreateOrganizationModal
            isOpen={showCreateModal.enabled}
            onClose={() => {
              setShowCreateModal({
                enabled: false,
              });
            }}
          />
        )}

        {showEditOrganizationModal.enabled && (
          <EditOrganizationModal
            isOpen={showEditOrganizationModal.enabled}
            callback={() => {
              console.log("saving new profile for organization");
            }}
            organization={showEditOrganizationModal.organization}
            onClose={() => {
              setShowEditOrganizationModal({
                enabled: false,
              });
            }}
          />
        )}
        <Flex flex="1" justifyContent="flex-end" px={3}>
          {data &&
            data.organizations.pageInfo &&
            feedParams &&
            getNewLocation && (
              <Pagination
                pageInfo={data.organizations.pageInfo}
                feedParams={feedParams}
                getNewLocation={getNewLocation}
              />
            )}
        </Flex>
      </Flex>
    </IndexWrap>
  );
};

export default OrganizationsDashboard;
