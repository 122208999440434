import { Text, Modal, ModalCloseButton } from "@skodel/sk-ui";
import React from "react";

export const BaseModalContent = ({ footer, body, onClose, title }: any) => {
  return (
    <>
      <Modal.Header>
        <Text>{title}</Text>
        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </>
  );
};
