import { useMutation } from "@apollo/client";
import {
  TextArea,
  Text,
  FormLabel,
  Modal,
  Button,
  ModalCloseButton,
  TextInput,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const CreateGroup = loader("./CreateGroup.graphql");

const CreateGroupModal: React.FC<{
  isOpen: boolean;
  onClose: () => any;
  organizationId: string;
}> = ({ isOpen, onClose, organizationId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [formState, setFormState]: any = useState({
    userIds: [],
    emails: "",
    name: "",
  });

  const [createGroup, createGroupState] = useMutation(CreateGroup);

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={t("CreateGroupModal.create_group", {
        defaultValue: "Create Group",
      })}
    >
      <Modal.Header>
        <Text>
          {t("CreateGroupModal.create_group", {
            defaultValue: "Create Group",
          })}
        </Text>
        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="name">
          {t("CreateGroupModal.name", { defaultValue: "Name" })}
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          mb="3"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />

        <>
          {/* <FormLabel p={2} px={0} htmlFor="named">
            Members
          </FormLabel>
          <AsyncSelect
            onChange={(userIds) =>
              setFormState({
                ...formState,
                userIds: userIds,
              })
            }
            value={formState.userIds}
            placeholder="Type facilitator or respondent name..."
            id="named"
            isMulti
            defaultOptions
            loadOptions={(inputValue: string) =>
              promiseOptions(client, inputValue)
            }
            name="named"
          /> */}

          <FormLabel p={2} px={0} htmlFor="name">
            {t("CreateGroupModal.emails", {
              defaultValue: "Emails",
            })}
          </FormLabel>
          <TextArea
            id="name"
            name="name"
            onChange={(e: any) =>
              setFormState({
                ...formState,
                emails: e.target.value,
              })
            }
            value={formState.emails}
            placeholder={t(
              "CreateGroupModal.janedoe@examplecomjohnsmith@examplecom",
              {
                defaultValue: "jane.doe@example.com\njohn.smith@example.com",
              }
            )}
            style={{
              border: "1px solid #CACACA",
              font: "inherit",
            }}
          />
          <Text color="grey" mt="2">
            {t("CreateGroupModal.add_emails_to_the_box_above", {
              defaultValue:
                "Add emails to the box above. Each email needs to start on a separate lines.",
            })}
            <br />
            <br />
          </Text>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={formState.name.length === 0 || createGroupState.loading}
          onClick={() => {
            createGroup({
              variables: {
                userIds: (formState.userIds || []).map((u: any) => u.value),
                emails: formState.emails
                  .trim()
                  .split("\n")
                  .filter((u: any) => u.length > 0),
                name: formState.name,
                organizationId,
              },
            })
              .then((data: any) => {
                toast.success(`Successfully created '${formState.name}'`);
                console.log(data.data.createGroup);
                history.push(`/group/${data.data.createGroup.group.id}`);
                onClose();
              })
              .catch((err: any) =>
                toast.error(
                  "Could not create group. Please check your internet connection and try again."
                )
              );
          }}
        >
          {t("CreateGroupModal.create", { defaultValue: "Create" })}
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default CreateGroupModal;
