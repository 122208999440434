import { useQuery } from "@apollo/react-hooks";
import {
  Flex,
  Button,
  PageLoader,
  Sidebar,
  SidebarItemGroup,
  SidebarNavLink,
  Box,
  PageHeading,
} from "@skodel/sk-ui";
import { MaxWidthContainer } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React from "react";
import { Link, Redirect, Route, Switch, useParams } from "react-router-dom";

import UserGroups from "./UserGroups";
import { GetUser } from "./types";

export const pushWithHook = (...args: any) => {
  // window.scrollTo(0, 0);
  args[0].push(args[1]);
};

const GET_USER = loader("./GetUser.graphql");

export const getNewLocation = (feedParams: any, location: any) => {
  const paramsWithoutNullOrEmptyValues = Object.fromEntries(
    Object.entries(feedParams)
      .filter(([_, v]) => v !== null && v !== "")
      .map((v: any) => {
        if (Array.isArray(v[1])) {
          return [v[0], v[1].join(",")];
        }

        return v;
      })
  );

  return {
    pathname: location.pathname,
    search: queryString.stringify(paramsWithoutNullOrEmptyValues),
  };
};

const UserSidebar = ({ user, ...args }: { user: any }) => {
  return (
    <Box
      maxWidth="268px"
      style={{
        flex: "1",
      }}
    >
      <Sidebar {...args}>
        <SidebarItemGroup label="Groups">
          <SidebarNavLink to={`/user/${user.id}/groups`}>
            All Groups
          </SidebarNavLink>
          <SidebarNavLink to={`/user/${user.id}/active-groups`}>
            Active Groups
          </SidebarNavLink>
          <SidebarNavLink to={`/user/${user.id}/archived-groups`}>
            Archived Groups
          </SidebarNavLink>
        </SidebarItemGroup>
      </Sidebar>
    </Box>
  );
};
export const IndexWrap = ({
  children,
  rhsChildren,
  headerChildren,
  organizationId,
  organizationName,
  user,
}: any) => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <MaxWidthContainer
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Flex flexDirection="column">
            <PageHeading>{headerChildren}</PageHeading>
            <Link to={`/organization/${organizationId}`}>
              {organizationName}
            </Link>
          </Flex>
          <Flex ml="4">{rhsChildren}</Flex>
        </MaxWidthContainer>
        <MaxWidthContainer
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <UserSidebar user={user} />
          {children}
        </MaxWidthContainer>
      </Flex>
    </>
  );
};

export const UserDashboardPage = ({ match }: any) => {
  const params: {
    userId: string;
  } = useParams();

  const { loading, data, error } = useQuery<GetUser>(GET_USER, {
    variables: {
      id: params.userId,
    },
  });

  if (!data?.user) {
    return <PageLoader />;
  }

  const user = data.user;

  return (
    <>
      <IndexWrap
        user={user}
        organizationName={user.organization.name}
        organizationId={user.organization.id}
        headerChildren={
          <>
            {user.firstName}&nbsp;{user.lastName}
          </>
        }
        rhsChildren={
          <>
            <Button variant="brand" disabled>
              Add to group
            </Button>
          </>
        }
      >
        <Flex flexDirection="column" flex="1">
          <Switch>
            <Redirect
              exact
              from={`${match.path}/`}
              to={`${match.path}/groups`}
            />

            <Route
              path={`${match.path}/groups`}
              render={() => (
                <UserGroups
                  groupStatus="ANY"
                  csvPrefix="all-groups"
                  userId={user.id}
                />
              )}
            />

            <Route
              path={`${match.path}/active-groups`}
              render={() => (
                <UserGroups
                  groupStatus="ACTIVE"
                  csvPrefix="active-groups"
                  userId={user.id}
                />
              )}
            />

            <Route
              path={`${match.path}/archived-groups`}
              render={() => (
                <UserGroups
                  groupStatus="ARCHIVED"
                  csvPrefix="archived-groups"
                  userId={user.id}
                />
              )}
            />
          </Switch>
        </Flex>
      </IndexWrap>
    </>
  );
};

export default UserDashboardPage;
