import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  FormLabel,
  Panel,
  Button,
  Text,
  TextInput,
  Checkbox,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { GetGroup } from "./types";

const GET_GROUP = loader("./GetGroup.graphql");
const UPDATE_GROUP_SETTINGS = loader("./UpdateGroupSettings.graphql");

const GroupSettingsForm = ({
  formState,
  setFormState,
  disabled,
}: {
  formState: {
    name: string;
    code: string | null;
    description: string | null;
    isArchived: boolean;
  };
  setFormState: any;
  disabled?: boolean;
}) => {
  return (
    <>
      <FormLabel p={2} px={0} htmlFor="name">
        Name
      </FormLabel>
      <TextInput
        id="name"
        name="name"
        style={{
          width: "100%",
        }}
        disabled={disabled}
        onChange={(e: any) =>
          setFormState({
            ...formState,
            name: e.target.value,
          })
        }
        value={formState.name || ""}
      />
      <FormLabel p={2} mt="3" px={0} htmlFor="description">
        Description
      </FormLabel>
      <TextInput
        id="description"
        name="description"
        style={{
          width: "100%",
        }}
        disabled={disabled}
        onChange={(e: any) =>
          setFormState({
            ...formState,
            description: e.target.value,
          })
        }
        value={formState.description || ""}
      />
      <FormLabel mt="3" p={2} px={0} htmlFor="code">
        Code
      </FormLabel>
      <TextInput
        id="code"
        name="code"
        style={{
          width: "100%",
        }}
        disabled={disabled}
        onChange={(e: any) =>
          setFormState({
            ...formState,
            code: e.target.value,
          })
        }
        value={formState.code || ""}
      />
      <FormLabel
        p={2}
        px={0}
        mt="3"
        htmlFor="is-archived"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Checkbox
          id="is-archived"
          name="is-archived"
          checked={formState.isArchived}
          onChange={(checked: boolean) =>
            setFormState({
              ...formState,
              isArchived: checked,
            })
          }
          mr={2}
        />
        <Text>Is this group archived?</Text>
      </FormLabel>
    </>
  );
};

export const GroupSettings = ({ groupId }: { groupId: string }) => {
  const params: {
    groupId: string;
  } = useParams();

  const { data } = useQuery<GetGroup>(GET_GROUP, {
    variables: {
      id: params.groupId,
    },
  });

  const [updateGroupSettings, updateGroupSettingsState] = useMutation(
    UPDATE_GROUP_SETTINGS
  );

  const [formState, setFormState] = useState<{
    name: string;
    code: string | null;
    description: string | null;
    isArchived: boolean;
  } | null>(null);

  useEffect(() => {
    if (data?.group) {
      const group = data?.group;
      setFormState({
        name: group.name,
        code: group.code,
        description: group.description,
        isArchived: group.isArchived,
      });
    }
  }, [data]);

  if (!data?.group || !formState) {
    return <Loader />;
  }

  return (
    <Panel.Base>
      <Panel.Header>
        <Panel.Heading>Group Settings</Panel.Heading>
      </Panel.Header>
      <Panel.Body>
        <GroupSettingsForm
          formState={formState}
          setFormState={setFormState}
          disabled={updateGroupSettingsState.loading}
        />
      </Panel.Body>
      <Panel.Footer>
        <Button
          disabled={true || updateGroupSettingsState.loading}
          onClick={async () => {
            try {
              const { data } = await updateGroupSettings({
                variables: {
                  id: params.groupId,
                  ...formState,
                },
              });

              console.log(data);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Save
        </Button>
      </Panel.Footer>
    </Panel.Base>
  );
};

export default GroupSettings;
