import { Box, Table, Modal, Button } from "@skodel/sk-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MAX_ROWS, MAX_COLUMNS } from ".";
import { BaseModalContent } from "./BaseModal";
import FlowHeading from "./FlowHeading";

export const SelectPage = ({
  setDataAndIncrementStep,
  state,
  onClose,
  title,
}: any) => {
  const { t } = useTranslation();

  const [currentRow, setCurrentRow] = useState(0);
  const currentRowViewIdx = Math.max(0, currentRow - Math.floor(MAX_ROWS / 2));

  return (
    <BaseModalContent
      onClose={onClose}
      title={title}
      body={
        <>
          <FlowHeading mb="2">
            {t("BulkImport.is_this_the_first_row_with_user_data", {
              defaultValue: "Is this the first row with user data?",
            })}
          </FlowHeading>
          <Box
            style={{
              maxHeight: "400px",
              overflow: "scroll",
            }}
          >
            <Table.Base>
              <Table.Body>
                {state.data
                  .slice(currentRowViewIdx, currentRowViewIdx + MAX_ROWS)
                  .map((row: any, rowIdx: any) => (
                    <Table.BodyRow
                      style={{
                        background:
                          rowIdx + currentRowViewIdx === currentRow
                            ? "#e9f4ff"
                            : undefined,
                      }}
                      key={rowIdx}
                    >
                      {row
                        .slice(0, MAX_COLUMNS)
                        .map((cell: any, cellId: any) => (
                          <Table.BodyData key={cellId}>{cell}</Table.BodyData>
                        ))}
                    </Table.BodyRow>
                  ))}
              </Table.Body>
            </Table.Base>
          </Box>
        </>
      }
      footer={
        <>
          <Modal.Footer>
            <Button
              variant="brand"
              ml={2}
              onClick={() => {
                setCurrentRow((currentRow + 1) % state.data.length);
              }}
            >
              {t("BulkImport.no", { defaultValue: "No" })}
            </Button>
            <Button
              variant="brand"
              ml={2}
              onClick={() => setDataAndIncrementStep(currentRow)}
            >
              {t("BulkImport.yes", { defaultValue: "Yes" })}
            </Button>
          </Modal.Footer>
        </>
      }
    />
  );
};
