import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Panel,
  FormLabel,
  TextInput,
  Flex,
  Box,
  LanguageSelect,
  Text,
  Image,
  Loader,
  Button,
  CountrySelect,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { GetOrganization } from "./types";

const GET_ORGANIZATION = loader("./GetOrganization.graphql");
const UPDATE_ORGANIZATION_PERSONALISATION_SETTINGS = loader(
  "./UpdateOrganizationPersonalisationSettings.graphql"
);
export const PersonalisationForm = ({
  formState,
  setFormState,
  disabled,
}: {
  formState: {
    languageId: {
      value: string;
      label: string;
    } | null;
    countryId: {
      value: string;
      label: string;
    } | null;
    name: string | null;
    brandLogoUrl: string | null;
  };
  setFormState: any;
  disabled?: boolean;
}) => {
  return (
    <>
      <FormLabel p={2} px={0} htmlFor="name">
        Name
      </FormLabel>
      <TextInput
        id="name"
        name="name"
        style={{
          width: "100%",
          marginBottom: "16px",
        }}
        disabled={disabled}
        onChange={(e: any) =>
          setFormState({
            ...formState,
            name: e.target.value,
          })
        }
        value={formState.name || ""}
      />
      {formState.brandLogoUrl && (
        <>
          <FormLabel p={2} px={0}>
            Custom Logo
          </FormLabel>
          <Flex mb="3" flexDirection="column" alignItems="flex-start">
            <Image
              src={formState.brandLogoUrl}
              mb={3}
              style={{
                height: "50px",
                width: "auto",
              }}
            />
            <Text mt={2} color="grey">
              Caption
            </Text>
          </Flex>
        </>
      )}
      <FormLabel p={2} px={0} htmlFor="check-in-style">
        Default Language
      </FormLabel>
      <Flex mb="3">
        <Box
          style={{
            flex: "1",
          }}
        >
          <LanguageSelect
            onChange={(languageId: any) =>
              setFormState({
                ...formState,
                languageId,
              })
            }
            isDisabled={disabled}
            value={formState.languageId}
            id="language"
            name="language"
          />
        </Box>
      </Flex>
      <FormLabel p={2} px={0} htmlFor="check-in-style">
        Country
      </FormLabel>
      <Flex mb="3">
        <Box
          style={{
            flex: "1",
          }}
        >
          <CountrySelect
            onChange={(countryId: any) =>
              setFormState({
                ...formState,
                countryId,
              })
            }
            isDisabled={disabled}
            value={formState.countryId}
            id="country"
            name="country"
          />
        </Box>
      </Flex>
    </>
  );
};

export const OrganizationPersonalisation = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const params: {
    organizationId: string;
  } = useParams();

  const { loading, data, error } = useQuery<GetOrganization>(GET_ORGANIZATION, {
    variables: {
      id: params.organizationId,
    },
  });

  const [
    updateOrganizationPersonalisationSettings,
    updateOrganizationPersonalisationSettingsState,
  ] = useMutation(UPDATE_ORGANIZATION_PERSONALISATION_SETTINGS);

  const [formState, setFormState] = useState<{
    name: string;
    languageId: {
      label: string;
      value: string;
    } | null;
    countryId: {
      label: string;
      value: string;
    } | null;
    brandLogoUrl: string | null;
  } | null>(null);

  useEffect(() => {
    if (data?.organization) {
      const organization = data?.organization;
      setFormState({
        name: organization.name,
        languageId: organization.defaultLanguage
          ? {
              value: organization.defaultLanguage.id,
              label: organization.defaultLanguage.name,
            }
          : null,
        countryId: organization.country
          ? {
              value: organization.country.id,
              label: organization.country.name,
            }
          : null,
        brandLogoUrl: organization.brandLogoUrl,
      });
    }
  }, [data]);

  if (!data?.organization || !formState) {
    return <Loader />;
  }

  return (
    <Panel.Base>
      <Panel.Header>
        <Panel.Heading>Personalisation</Panel.Heading>
      </Panel.Header>
      <Panel.Body>
        <PersonalisationForm
          disabled={updateOrganizationPersonalisationSettingsState.loading}
          formState={formState}
          setFormState={setFormState}
        />
      </Panel.Body>
      <Panel.Footer>
        <Button
          disabled={updateOrganizationPersonalisationSettingsState.loading}
          onClick={async () => {
            try {
              const { data } = await updateOrganizationPersonalisationSettings({
                variables: {
                  ...formState,
                  id: params.organizationId,
                  countryId: formState.countryId
                    ? formState.countryId.value
                    : null,
                  defaultLanguageId: formState.languageId
                    ? formState.languageId.value
                    : null,
                },
              });
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Save
        </Button>
      </Panel.Footer>
    </Panel.Base>
  );
};

export default OrganizationPersonalisation;
