import React from "react";

import Navbar from "./components/navbar";

export const PageWrapper = ({ children, history }: any) => (
  <>
    <Navbar showSearchAndUser history={history} />
    {children}
  </>
);
