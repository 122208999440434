import { useQuery } from "@apollo/react-hooks";
import {
  Flex,
  Button,
  PageLoader,
  Sidebar,
  SidebarPrimaryNavLink,
  SidebarItemGroup,
  SidebarNavLink,
  Box,
  PageHeading,
} from "@skodel/sk-ui";
import { MaxWidthContainer } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useState } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";

import AddBulkUsersModal from "../components/bulk-import-flow/Modal";
import CreateUserModal from "../modals/CreateUserModal";
import CreateGroupModal from "../modals/create-group";
import OrganizationCheckInSettings from "./OrganizationCheckInSettings";
import OrganizationDashboard from "./OrganizationDashboard";
import OrganizationGroups from "./OrganizationGroups";
import OrganizationPersonalisation from "./OrganizationPersonalisation";
import OrganizationUsers from "./OrganizationUsers";
import { GetOrganization } from "./types";

export const pushWithHook = (...args: any) => {
  // window.scrollTo(0, 0);
  args[0].push(args[1]);
};

const GET_ORGANIZATION = loader("./GetOrganization.graphql");

export const getNewLocation = (feedParams: any, location: any) => {
  const paramsWithoutNullOrEmptyValues = Object.fromEntries(
    Object.entries(feedParams)
      .filter(([_, v]) => v !== null && v !== "")
      .map((v: any) => {
        if (Array.isArray(v[1])) {
          return [v[0], v[1].join(",")];
        }

        return v;
      })
  );

  return {
    pathname: location.pathname,
    search: queryString.stringify(paramsWithoutNullOrEmptyValues),
  };
};

const OrganizationSidebar = ({
  organization,
  ...args
}: {
  organization: any;
}) => {
  return (
    <Box
      maxWidth="268px"
      style={{
        flex: "1",
      }}
    >
      <Sidebar {...args}>
        <SidebarPrimaryNavLink
          to={`/organization/${organization.id}/dashboard`}
        >
          Dashboard
        </SidebarPrimaryNavLink>
        <SidebarPrimaryNavLink
          to={`/organization/${organization.id}/personalisation`}
        >
          Personalisation
        </SidebarPrimaryNavLink>
        <SidebarPrimaryNavLink
          to={`/organization/${organization.id}/check-in-settings`}
        >
          Check-in Settings
        </SidebarPrimaryNavLink>
        <SidebarItemGroup label="Users">
          <SidebarNavLink to={`/organization/${organization.id}/users`}>
            All users
          </SidebarNavLink>
          <SidebarNavLink to={`/organization/${organization.id}/active-users`}>
            Active users
          </SidebarNavLink>
          <SidebarNavLink
            to={`/organization/${organization.id}/disabled-users`}
          >
            Disabled users
          </SidebarNavLink>
          <SidebarNavLink to={`/organization/${organization.id}/facilitators`}>
            Facilitators
          </SidebarNavLink>
          <SidebarNavLink to={`/organization/${organization.id}/respondents`}>
            Respondents
          </SidebarNavLink>
          {/* <SidebarNavLink
            to={`/organization/${organization.id}/users-in-no-groups`}
          >
            Users in no groups
          </SidebarNavLink> */}
        </SidebarItemGroup>
        <SidebarItemGroup label="Groups">
          <SidebarNavLink to={`/organization/${organization.id}/groups`}>
            All Groups
          </SidebarNavLink>
          <SidebarNavLink to={`/organization/${organization.id}/active-groups`}>
            Active Groups
          </SidebarNavLink>
          <SidebarNavLink
            to={`/organization/${organization.id}/archived-groups`}
          >
            Archived Groups
          </SidebarNavLink>
        </SidebarItemGroup>
      </Sidebar>
    </Box>
  );
};
export const IndexWrap = ({
  children,
  rhsChildren,
  headerChildren,
  organization,
}: any) => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <MaxWidthContainer
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <PageHeading>
            {/* <OrganizationName /> */}
            {headerChildren}
          </PageHeading>
          <Flex ml="4">{rhsChildren}</Flex>
        </MaxWidthContainer>
        <MaxWidthContainer
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "16px",
          }}
        >
          <OrganizationSidebar organization={organization} />
          {children}
        </MaxWidthContainer>
      </Flex>
    </>
  );
};

export const OrganizationDashboardPage = ({ match }: any) => {
  const [showCreateUserModal, setShowCreateUserModal]: any = useState({
    enabled: false,
  });

  const [showCreateGroupModal, setShowCreateGroupModal]: any = useState({
    enabled: false,
  });

  const [showAddBulkUsersModal, setshowAddBulkUsersModal] = useState({
    enabled: false,
  });

  const params: {
    organizationId: string;
  } = useParams();

  const { loading, data, error } = useQuery<GetOrganization>(GET_ORGANIZATION, {
    variables: {
      id: params.organizationId,
    },
  });

  if (!data?.organization) {
    return <PageLoader />;
  }

  const organization = data.organization;

  return (
    <>
      <IndexWrap
        organization={organization}
        headerChildren={organization.name}
        rhsChildren={
          <>
            <Button
              variant="brand"
              onClick={() =>
                setShowCreateGroupModal({
                  enabled: true,
                })
              }
              disabled
            >
              Create a new group
            </Button>
            <Button
              ml="3"
              variant="brand"
              onClick={() =>
                setShowCreateUserModal({
                  enabled: true,
                })
              }
            >
              Create a new user
            </Button>
            <Button
              ml="3"
              variant="brand"
              onClick={() =>
                setshowAddBulkUsersModal({
                  enabled: true,
                })
              }
              disabled
            >
              Bulk import users
            </Button>
          </>
        }
      >
        <Flex flexDirection="column" flex="1">
          <Switch>
            <Redirect
              exact
              from={`${match.path}/`}
              to={`${match.path}/dashboard`}
            />
            <Route
              path={`${match.path}/dashboard`}
              render={() => (
                <OrganizationDashboard organizationId={organization.id} />
              )}
            />

            <Route
              path={`${match.path}/personalisation`}
              render={() => (
                <OrganizationPersonalisation organizationId={organization.id} />
              )}
            />

            <Route
              path={`${match.path}/check-in-settings`}
              render={() => (
                <OrganizationCheckInSettings organizationId={organization.id} />
              )}
            />

            <Route
              path={`${match.path}/users`}
              render={() => (
                <OrganizationUsers
                  organizationId={organization.id}
                  csvPrefix="all-users"
                />
              )}
            />
            <Route
              path={`${match.path}/facilitators`}
              render={() => (
                <OrganizationUsers
                  organizationId={organization.id}
                  csvPrefix="facilitators"
                  accountTypeNotIn={["USER"]}
                />
              )}
            />
            <Route
              path={`${match.path}/respondents`}
              render={() => (
                <OrganizationUsers
                  organizationId={organization.id}
                  accountTypeIn={["USER"]}
                  csvPrefix="respondents"
                />
              )}
            />
            <Route
              path={`${match.path}/active-users`}
              render={() => (
                <OrganizationUsers
                  organizationId={organization.id}
                  userStatus="ACTIVE"
                  csvPrefix="active-users"
                />
              )}
            />
            <Route
              path={`${match.path}/disabled-users`}
              render={() => (
                <OrganizationUsers
                  organizationId={organization.id}
                  userStatus="DISABLED"
                  csvPrefix="disabled-users"
                />
              )}
            />
            <Route
              path={`${match.path}/users-in-no-groups`}
              render={() => (
                <OrganizationUsers
                  csvPrefix="users-in-no-groups"
                  organizationId={organization.id}
                />
              )}
            />

            <Route
              path={`${match.path}/groups`}
              render={() => (
                <OrganizationGroups
                  csvPrefix="all-groups"
                  groupStatus="ANY"
                  organizationId={organization.id}
                />
              )}
            />

            <Route
              path={`${match.path}/active-groups`}
              render={() => (
                <OrganizationGroups
                  csvPrefix="active-groups"
                  groupStatus="ACTIVE"
                  organizationId={organization.id}
                />
              )}
            />

            <Route
              path={`${match.path}/archived-groups`}
              render={() => (
                <OrganizationGroups
                  csvPrefix="archived-groups"
                  groupStatus="ARCHIVED"
                  organizationId={organization.id}
                />
              )}
            />
          </Switch>
        </Flex>
      </IndexWrap>
      {showCreateUserModal.enabled && (
        <CreateUserModal
          isOpen={showCreateUserModal.enabled}
          organization={{
            value: organization.id,
            label: organization.name,
          }}
          onClose={() => {
            setShowCreateUserModal({
              enabled: false,
            });
          }}
        />
      )}
      {showCreateGroupModal.enabled && (
        <CreateGroupModal
          isOpen={showCreateGroupModal.enabled}
          organizationId={organization.id}
          onClose={() => {
            setShowCreateGroupModal({
              enabled: false,
            });
          }}
        />
      )}
      {showAddBulkUsersModal.enabled && (
        <AddBulkUsersModal
          isOpen={showAddBulkUsersModal.enabled}
          title={"Bulk Import Users"}
          preludeText={"prelude text"}
          confirmationTextAddendum={
            "They will not receive any sign up email, but they will be able to immediately access their accounts at check-in.skodel.com."
          }
          onClose={() =>
            setshowAddBulkUsersModal({
              enabled: false,
            })
          }
        />
      )}
    </>
  );
};

export default OrganizationDashboardPage;
