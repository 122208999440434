import { loader } from "graphql.macro";

export const GetBrandLogo = loader("./GetBrandLogo.graphql");
export const GetUnreadNotificationCount = loader(
  "./GetUnreadNotificationCount.graphql"
);
export const GetUserAccountImpersonation = loader(
  "./GetUserAccountImpersonation.graphql"
);
export const GetViewerNotificationsForDropdown = loader(
  "./GetViewerNotificationsForDropdown.graphql"
);

export const AcknowledgeNotifications = loader(
  "./AcknowledgeNotifications.graphql"
);
export const InteractWithNotification = loader(
  "./InteractWithNotification.graphql"
);
export const RecordPageView = loader("./RecordPageView.graphql");

export const StopImpersonating = loader("./StopImpersonating.graphql");
export const UpdateLanguage = loader("./UpdateLanguage.graphql");
