import { Table, Button } from "@skodel/sk-ui";
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { MAX_ROWS, MAX_COLUMNS } from ".";
import { BaseModalContent } from "./BaseModal";
import FlowHeading from "./FlowHeading";

const COLUMN_NAMES = ["first name", "last name", "email"];

const getNextColumn = (
  currentColumn: number,
  columnCount: number,
  rowMap: any
) => {
  if (Object.keys(rowMap).length === COLUMN_NAMES.length) {
    throw Error();
  }

  const rowMapValues = Object.values(rowMap);

  currentColumn = (currentColumn + 1) % columnCount;

  while (rowMapValues.includes(currentColumn)) {
    currentColumn = (currentColumn + 1) % columnCount;
  }

  return currentColumn;
};

export const SelectColumnsPage = ({
  setDataAndIncrementStep,
  state,
  onClose,
  title,
}: any) => {
  const { t } = useTranslation();

  const [currentFieldIdx, setCurrentFieldIdx] = useState(0);
  const [currentColumn, setCurrentColumn] = useState(0);
  const [rowMap, setRowMap] = useState({});
  const [invertedRowMap, setInvertedRowMap]: any = useState({});

  let currentRowViewIdx = state.firstRowIdx;

  return (
    <BaseModalContent
      title={title}
      onClose={onClose}
      body={
        <>
          <FlowHeading mb="2">
            <Trans t={t} i18nKey="BulkImport.is_this_column_a_user_field">
              Is this column a user{" "}
              {{ fieldName: COLUMN_NAMES[currentFieldIdx] }}?
            </Trans>
          </FlowHeading>
          <Table.Base>
            <Table.Header>
              <Table.HeaderRow>
                {state.data[currentRowViewIdx]
                  .slice(0, MAX_COLUMNS)
                  .map((cell: any, cellId: any) => (
                    <Table.HeaderData key={cellId}>
                      {
                        // @ts-ignore
                        cellId in invertedRowMap
                          ? COLUMN_NAMES[invertedRowMap[cellId]]
                          : "?"
                      }
                    </Table.HeaderData>
                  ))}
              </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
              {state.data
                .slice(currentRowViewIdx, currentRowViewIdx + MAX_ROWS)
                .map((row: any, rowId: any) => (
                  <Table.BodyRow key={rowId}>
                    {row.slice(0, MAX_COLUMNS).map((cell: any, cellId: any) => (
                      <Table.BodyData
                        style={{
                          background:
                            cellId === currentColumn ? "#e9f4ff" : undefined,
                        }}
                        key={cellId}
                      >
                        {cell}
                      </Table.BodyData>
                    ))}
                  </Table.BodyRow>
                ))}
            </Table.Body>
          </Table.Base>
        </>
      }
      footer={
        <>
          <Button
            variant="brand"
            ml={2}
            onClick={() => {
              setCurrentColumn(
                getNextColumn(currentColumn, state.data[0].length, rowMap)
              );
              // }
            }}
          >
            {t("BulkImport.no", { defaultValue: "No" })}
          </Button>
          <Button
            variant="brand"
            ml={2}
            onClick={() => {
              const newRowMap = {
                ...rowMap,
                [COLUMN_NAMES[currentFieldIdx]]: currentColumn,
              };

              setRowMap(newRowMap);

              const newInvertedRowMap = {
                ...invertedRowMap,
                [currentColumn]: currentFieldIdx,
              };

              setInvertedRowMap(newInvertedRowMap);

              if (Object.keys(rowMap).length + 1 === COLUMN_NAMES.length) {
                setDataAndIncrementStep(newRowMap);
              } else {
                setCurrentFieldIdx(currentFieldIdx + 1);
                setCurrentColumn(
                  getNextColumn(currentColumn, state.data[0].length, newRowMap)
                );
              }
            }}
          >
            {t("BulkImport.yes", { defaultValue: "Yes" })}
          </Button>
        </>
      }
    />
  );
};
