import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Pagination, TextInput, Loader } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getNewLocation, pushWithHook } from ".";
import { GroupTable } from "../components/group-table";
import CsvDownloader, {
  GroupEdgeMapper,
} from "../components/user-csv-downloader";
import { GetOrganizationGroups } from "./types";

const GET_ORGANIZATION_GROUPS = loader("./GetOrganizationGroups.graphql");

type GroupFeedParams = {
  page?: number;
  count?: number;
  nameQuery?: string;
};

export const getGroupFeedParams: (location: any) => GroupFeedParams = (
  location: any
) => {
  const query: {
    page?: string;
    count?: string;
    nameQuery?: string;
  } = queryString.parse(location.search);

  const feedParams: GroupFeedParams = {
    page: 1,
  };

  if (query.page) {
    feedParams.page = parseInt(query.page || "1");
  }

  if (query.count) {
    feedParams.page = parseInt(query.count || "20");
  }

  if (query.nameQuery) {
    feedParams.nameQuery = query.nameQuery;
  }

  return feedParams;
};

export const OrganizationGroups = ({
  organizationId,
  groupStatus,
  csvPrefix,
}: {
  organizationId: string;
  csvPrefix: string;
  groupStatus?: "ACTIVE" | "ARCHIVED" | "ANY";
}) => {
  const location = useLocation();
  const history = useHistory();
  const feedParams = getGroupFeedParams(location);

  const setNameQuery = useCallback(
    (nameQuery: string) => {
      pushWithHook(
        history,
        getNewLocation(
          {
            ...feedParams,
            page: 1,
            nameQuery,
          } as any,
          location
        )
      );
    },
    [pushWithHook, getNewLocation, history, feedParams, location]
  );

  const variables = {
    ...feedParams,
    id: organizationId,
    groupStatus,
  };

  const { loading, data, error } = useQuery<GetOrganizationGroups>(
    GET_ORGANIZATION_GROUPS,
    {
      variables,
    }
  );

  const filterBar = (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      style={{
        gap: "8px",
      }}
      mb="3"
    >
      <TextInput
        placeholder="Type here to search by name"
        value={feedParams.nameQuery}
        onChange={(e) => setNameQuery(e.target.value)}
      />
    </Flex>
  );

  if (!data?.organization) {
    return (
      <Flex flexDirection="column">
        {filterBar}
        <Flex alignItems={"center"} justifyContent="center" width="100%" p={5}>
          <Loader />
        </Flex>
      </Flex>
    );
  }

  const edges = data?.organization?.groups?.edges || [];

  if (edges.length === 0) {
    return (
      <Flex flexDirection="column">
        {filterBar}
        <Flex alignItems={"center"} justifyContent="center" width="100%" p={5}>
          <Text fontSize="3">No results.</Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column">
      {filterBar}
      <GroupTable groups={edges.map((edge: any) => edge.node)} />
      <Flex
        flex="1"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        mt="3"
      >
        <CsvDownloader
          edgeMapper={GroupEdgeMapper}
          getConnectionFromData={(data) => data?.organization?.groups}
          namePrefix={csvPrefix}
          query={GET_ORGANIZATION_GROUPS}
          variables={variables}
        />
        <Flex flex="1" justifyContent="flex-end" flexDirection="row">
          {data &&
            data?.organization?.groups?.pageInfo &&
            feedParams &&
            getNewLocation && (
              <Pagination
                pageInfo={data?.organization?.groups.pageInfo}
                feedParams={feedParams}
                getNewLocation={getNewLocation}
              />
            )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrganizationGroups;
