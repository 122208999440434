import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  Box,
  Text,
  FormLabel,
  Select,
  Panel,
  Checkbox,
  Button,
} from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import { groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import { GetOrganization } from "./types";

const GET_ORGANIZATION = loader("./GetOrganization.graphql");
const UPDATE_ORGANIZATION_CHECK_IN_SETTINGS = loader(
  "./UpdateOrganizationCheckInSettings.graphql"
);

export type AnonymitySettings =
  | "ANONYMOUS"
  | "IDENTIFIED"
  | "CHOOSE_IDENTIFIED_DEFAULT"
  | "CHOOSE_ANONYMOUS_DEFAULT";

export type AnonymityType = "ANONYMOUS" | "IDENTIFIED";

const DEFAULT_DAYS_FOR_REMINDER = 1;
const MINIMUM_DAYS_FOR_REMINDERS = 1;

const buildDayLabel = (value: number): DropdownValue<number> => ({
  label: `${value} day${value !== 1 ? "s" : ""}`, // TODO: handle i18n
  value,
});

const buildDayArrayLabels: any = (dayCount: number, startFrom?: number) => {
  if (!startFrom) {
    startFrom = 1;
  }

  if (dayCount - startFrom < 0) {
    return 0;
  }

  // @ts-ignore
  return [...new Array(dayCount - startFrom).keys()]
    .map((value) => value + startFrom)
    .map((value) => buildDayLabel(value));
};

const MarkFinishedAfterOptions: any = buildDayArrayLabels(7);

const CHECKBOX_INDENT = 20;
const checkInAnonymityOptionsForFacilitator = (
  cao: {
    value: string;
    facilitatorLabelSchool: string;
    facilitatorLabelWorkplace: string;
    selfCheckInLabelSchool: string;
    selfCheckInLabelWorkplace: string;
  },
  isSchoolOrganizationType: boolean
) => {
  return {
    value: cao.value,
    label: isSchoolOrganizationType
      ? cao.facilitatorLabelSchool
      : cao.facilitatorLabelWorkplace,
  };
};

const checkInAnonymityOptionsForSelfCheckIn = (
  cao: {
    value: string;
    facilitatorLabelSchool: string;
    facilitatorLabelWorkplace: string;
    selfCheckInLabelSchool: string;
    selfCheckInLabelWorkplace: string;
  },
  isSchoolOrganizationType: boolean
) => {
  return {
    value: cao.value,
    label: isSchoolOrganizationType
      ? cao.selfCheckInLabelSchool
      : cao.selfCheckInLabelWorkplace,
  };
};

const CheckInAnonymityOptions = [
  {
    value: "CHOOSE_IDENTIFIED_DEFAULT",
    facilitatorLabelWorkplace:
      "Allow team leaders to choose (with identified check-ins being the default)",
    facilitatorLabelSchool:
      "Allow teachers to choose (with identified check-ins being the default)",
    selfCheckInLabelWorkplace:
      "Allow team members to choose (with identified self check-ins being the default)",
    selfCheckInLabelSchool:
      "Allow students to choose (with identified self check-ins being the default)",
  },
  {
    value: "CHOOSE_ANONYMOUS_DEFAULT",
    facilitatorLabelWorkplace:
      "Allow team leaders to choose (with anonymous check-ins being the default)",
    facilitatorLabelSchool:
      "Allow teachers to choose (with anonymous check-ins being the default)",
    selfCheckInLabelWorkplace:
      "Allow team members to choose (with anonymous self check-ins being the default)",
    selfCheckInLabelSchool:
      "Allow students to choose (with anonymous self check-ins being the default)",
  },
  {
    value: "ANONYMOUS",
    facilitatorLabelWorkplace: "Only allow anonymous check-ins",
    facilitatorLabelSchool: "Only allow anonymous check-ins",
    selfCheckInLabelWorkplace: "Only allow anonymous self check-ins",
    selfCheckInLabelSchool: "Only allow anonymous self check-ins",
  },
  {
    value: "IDENTIFIED",
    facilitatorLabelWorkplace: "Only allow identified check-ins",
    facilitatorLabelSchool: "Only allow identified check-ins",
    selfCheckInLabelWorkplace: "Only allow identified self check-ins",
    selfCheckInLabelSchool: "Only allow identified self check-ins",
  },
];

const CheckInAnonymityOptionsMap = groupBy(CheckInAnonymityOptions, "value");

type DropdownValue<V = string> = {
  label: string;
  value: V;
};

const CheckInSettingsForm = ({
  formState,
  setFormState,
  disabled,
}: {
  formState: {
    anonymitySettings: AnonymitySettings;
    selfCheckInAnonymitySettings: AnonymitySettings;
    staffCanSelfCheckIn: boolean;
    respondentsCanSelfCheckIn: boolean;
    emailStaffOnSelfCheckIn: boolean;
    respondentsCanCommentOnTheirCheckIns: boolean;
    hasReactions: boolean;
    markAsFinishedAfter: DropdownValue<number> | null;
    remindUserAfter: DropdownValue<number> | null;
  };
  setFormState: any;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const isSchoolOrganizationType = true; // TODO

  return (
    <>
      <Box>
        <FormLabel p={2} px={0} htmlFor="name">
          {t("GeneralSettings.mark_check-ins_as_finished_after", {
            defaultValue: "Mark check-ins as finished after",
          })}
        </FormLabel>
        <Box mb={3}>
          <Select
            id="mark-as-finished-after"
            name="mark-as-finished-after"
            isDisabled={disabled}
            value={formState.markAsFinishedAfter}
            onChange={(newValue: any) => {
              let remindUserAfter = formState.remindUserAfter;

              if (newValue && newValue.value <= MINIMUM_DAYS_FOR_REMINDERS) {
                remindUserAfter = null;
              } else if (
                newValue &&
                formState.remindUserAfter &&
                newValue.value <= formState.remindUserAfter.value
              ) {
                remindUserAfter = buildDayLabel(newValue.value - 1);
              } else if (formState.remindUserAfter === null) {
                remindUserAfter = buildDayLabel(DEFAULT_DAYS_FOR_REMINDER);
              }

              setFormState({
                ...formState,
                markAsFinishedAfter: newValue,
                remindUserAfter: remindUserAfter,
              });
            }}
            options={MarkFinishedAfterOptions}
          />
        </Box>
        <FormLabel p={2} px={0} htmlFor="name">
          {t("GeneralSettings.when_to_send_check_in_reminder", {
            defaultValue:
              "When to send a reminder to users who haven't finished a check in",
          })}
        </FormLabel>
        <Box mb={3}>
          <Select
            id="when-to-send-check-in-reminder"
            name="when-to-send-check-in-reminder"
            isDisabled={
              disabled ||
              (!!formState.markAsFinishedAfter &&
                formState.markAsFinishedAfter.value <= 1)
            }
            value={formState.remindUserAfter}
            onChange={(newValue: any) =>
              setFormState({
                ...formState,
                remindUserAfter: newValue,
              })
            }
            options={buildDayArrayLabels(
              Math.min(
                formState.markAsFinishedAfter
                  ? formState.markAsFinishedAfter.value
                  : 0,
                7
              ),
              MINIMUM_DAYS_FOR_REMINDERS
            )}
          />
        </Box>
      </Box>
      <Box>
        <Text
          p={2}
          pt={3}
          pb={0}
          px={0}
          htmlFor="name"
          fontFamily="Nunito Sans Bold"
        >
          {t("GeneralSettings.self-check-ins-group", {
            defaultValue: "Self Check-Ins",
          })}
        </Text>
        <Box
          style={{
            paddingLeft: CHECKBOX_INDENT,
          }}
        >
          <FormLabel
            p={2}
            px={0}
            htmlFor="beep2"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              id="hide-empty-checkins"
              name="hide-empty-checkins"
              checked={formState.respondentsCanSelfCheckIn}
              disabled={disabled}
              onChange={(checked: boolean) =>
                setFormState({
                  ...formState,
                  respondentsCanSelfCheckIn: checked,
                })
              }
              mr={2}
            />
            {isSchoolOrganizationType ? (
              <Trans t={t} i18nKey="GeneralSettings.students_can_self_check-in">
                Students can self check-in
              </Trans>
            ) : (
              <Trans
                t={t}
                i18nKey="GeneralSettings.team_members_can_self_check-in"
              >
                Team members can self check-in
              </Trans>
            )}
          </FormLabel>
          <>
            <FormLabel
              p={2}
              px={0}
              htmlFor="beep2"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                id="hide-empty-checkins"
                name="hide-empty-checkins"
                checked={formState.staffCanSelfCheckIn}
                disabled={disabled}
                onChange={(checked: boolean) =>
                  setFormState({
                    ...formState,
                    staffCanSelfCheckIn: checked,
                  })
                }
                mr={2}
              />
              {isSchoolOrganizationType ? (
                <Trans
                  t={t}
                  i18nKey="GeneralSettings.teachers_can_self_check-in"
                >
                  Teachers can self check-in
                </Trans>
              ) : (
                <Trans
                  t={t}
                  i18nKey="GeneralSettings.team_leaders_can_self_check-in"
                >
                  Team leaders can self check-in
                </Trans>
              )}
            </FormLabel>
            <FormLabel
              p={2}
              px={0}
              htmlFor="beep2"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                id="email-staff-on-check-in"
                name="email-staff-on-check-in"
                checked={formState.emailStaffOnSelfCheckIn}
                disabled={disabled}
                onChange={(checked: boolean) =>
                  setFormState({
                    ...formState,
                    emailStaffOnSelfCheckIn: checked,
                  })
                }
                mr={2}
              />

              <Trans
                t={t}
                i18nKey="GeneralSettings.email_appropriate_staff_when_a_self_chec"
              >
                Email appropriate staff when a self check-in occurs
              </Trans>
            </FormLabel>
          </>
        </Box>
        <Box>
          <Text
            p={2}
            pt={3}
            pb={0}
            px={0}
            htmlFor="name"
            fontFamily="Nunito Sans Bold"
          >
            {t("GeneralSettings.comments-group", {
              defaultValue: "Comments",
            })}
          </Text>
          <Box
            style={{
              paddingLeft: CHECKBOX_INDENT,
            }}
          >
            <FormLabel
              p={2}
              px={0}
              htmlFor="should-enable-reactions-comments"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                id="should-enable-reactions-comments"
                name="should-enable-reactions-comments"
                disabled={disabled}
                checked={formState.hasReactions}
                onChange={(checked: boolean) =>
                  setFormState({
                    ...formState,
                    hasReactions: checked,
                  })
                }
                mr={2}
              />
              <Trans t={t} i18nKey="GeneralSettings.enable_reactions_comments">
                Enable Reactions/Comments
              </Trans>
            </FormLabel>
            <FormLabel
              p={2}
              px={0}
              htmlFor="beep2"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                id="respondents-can-comment-on-own"
                name="respondents-can-comment-on-own"
                checked={formState.respondentsCanCommentOnTheirCheckIns}
                disabled={disabled}
                onChange={(checked: boolean) =>
                  setFormState({
                    ...formState,
                    respondentsCanCommentOnTheirCheckIns: checked,
                  })
                }
                mr={2}
              />
              {isSchoolOrganizationType ? (
                <Trans
                  t={t}
                  i18nKey="GeneralSettings.respondents_can_comment_on_own_school"
                >
                  Students (and teachers if allowed) can comment on their own
                  check-ins
                </Trans>
              ) : (
                <Trans
                  t={t}
                  i18nKey="GeneralSettings.respondents_can_comment_on_own_workplace"
                >
                  Team members can comment on their own check-ins
                </Trans>
              )}
            </FormLabel>
          </Box>
        </Box>
      </Box>
      <Box>
        <Text p={2} pb={0} px={0} htmlFor="name" fontFamily="Nunito Sans Bold">
          {t("GeneralSettings.anonymity-settings-group", {
            defaultValue: "Anonymous Check-Ins",
          })}
        </Text>
        <Box>
          <FormLabel p={2} px={0} htmlFor="name">
            {isSchoolOrganizationType
              ? t(
                  "GeneralSettings.default_facilitator_check_in_setting_school",
                  {
                    defaultValue:
                      "Default check-in anonymity for scheduled check-ins or check-ins run by teachers",
                  }
                )
              : t(
                  "GeneralSettings.default_facilitator_check_in_setting_workplace",
                  {
                    defaultValue:
                      "Default check-in anonymity for scheduled check-ins or check-ins run by team leaders",
                  }
                )}
          </FormLabel>
          <Box mb={3}>
            <Select
              id="mark-as-finished-after"
              name="mark-as-finished-after"
              isDisabled={disabled}
              value={checkInAnonymityOptionsForFacilitator(
                CheckInAnonymityOptionsMap[formState.anonymitySettings][0],
                isSchoolOrganizationType
              )}
              onChange={(newValue: any) => {
                setFormState({
                  ...formState,
                  anonymitySettings: newValue.value,
                });
              }}
              options={CheckInAnonymityOptions.map((cao) =>
                checkInAnonymityOptionsForFacilitator(
                  cao,
                  isSchoolOrganizationType
                )
              )}
            />
          </Box>
          <FormLabel p={2} px={0} htmlFor="name">
            {t("GeneralSettings.default_self_check_in_setting", {
              defaultValue: "Default self check-in anonymity",
            })}
          </FormLabel>
          <Box mb={3}>
            <Select
              id="mark-as-finished-after"
              name="mark-as-finished-after"
              isDisabled={disabled}
              value={checkInAnonymityOptionsForSelfCheckIn(
                CheckInAnonymityOptionsMap[
                  formState.selfCheckInAnonymitySettings
                ][0],
                isSchoolOrganizationType
              )}
              onChange={(newValue: any) => {
                setFormState({
                  ...formState,
                  selfCheckInAnonymitySettings: newValue.value,
                });
              }}
              options={CheckInAnonymityOptions.map((cao) =>
                checkInAnonymityOptionsForSelfCheckIn(
                  cao,
                  isSchoolOrganizationType
                )
              )}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const OrganizationCheckInSettings = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const params: {
    organizationId: string;
  } = useParams();

  const { loading, data, error } = useQuery<GetOrganization>(GET_ORGANIZATION, {
    variables: {
      id: params.organizationId,
    },
  });

  const [
    updateOrganizationCheckInSettings,
    updateOrganizationCheckInSettingsState,
  ] = useMutation(UPDATE_ORGANIZATION_CHECK_IN_SETTINGS);

  const [formState, setFormState] = useState<{
    anonymitySettings: AnonymitySettings;
    selfCheckInAnonymitySettings: AnonymitySettings;
    staffCanSelfCheckIn: boolean;
    respondentsCanSelfCheckIn: boolean;
    emailStaffOnSelfCheckIn: boolean;
    respondentsCanCommentOnTheirCheckIns: boolean;
    hasReactions: boolean;
    markAsFinishedAfter: DropdownValue<number> | null;
    remindUserAfter: DropdownValue<number> | null;
  } | null>(null);

  useEffect(() => {
    if (data?.organization) {
      const organization = data?.organization;
      setFormState({
        staffCanSelfCheckIn: organization.staffCanSelfCheckIn,
        respondentsCanSelfCheckIn: organization.respondentsCanSelfCheckIn,

        anonymitySettings: organization.anonymitySettings,
        emailStaffOnSelfCheckIn: organization.emailStaffOnSelfCheckIn,
        selfCheckInAnonymitySettings: organization.selfCheckInAnonymitySettings,
        respondentsCanCommentOnTheirCheckIns:
          organization.respondentsCanCommentOnTheirCheckIns,
        hasReactions: organization.hasReactions,
        markAsFinishedAfter: buildDayLabel(organization.markAsFinishedAfter),
        remindUserAfter: organization.remindUserAfter
          ? buildDayLabel(organization.remindUserAfter)
          : null,
      });
    }
  }, [data]);

  if (!data?.organization || !formState) {
    return <Loader />;
  }

  const organization = data.organization;

  return (
    <Panel.Base>
      <Panel.Header>
        <Panel.Heading>Check-in Settings</Panel.Heading>
      </Panel.Header>
      <Panel.Body>
        <CheckInSettingsForm
          disabled={updateOrganizationCheckInSettingsState.loading}
          formState={formState}
          setFormState={setFormState}
        />
      </Panel.Body>

      <Panel.Footer>
        <Button
          disabled={updateOrganizationCheckInSettingsState.loading}
          onClick={async () => {
            try {
              const { data } = await updateOrganizationCheckInSettings({
                variables: {
                  id: params.organizationId,
                  ...(formState.hasReactions !== organization.hasReactions
                    ? {
                        hasReactions: formState.hasReactions,
                      }
                    : {}),
                  ...(formState.markAsFinishedAfter?.value !==
                    organization.markAsFinishedAfter &&
                  formState.markAsFinishedAfter
                    ? {
                        markAsFinishedAfter:
                          formState.markAsFinishedAfter.value,
                      }
                    : {}),
                  // @ts-ignore
                  ...(formState.remindUserAfter !== // @ts-ignore
                    organization.remindUserAfter && formState.remindUserAfter
                    ? {
                        remindUserAfter: formState.remindUserAfter.value,
                      }
                    : {}),
                  ...(formState.respondentsCanSelfCheckIn !==
                  organization.respondentsCanSelfCheckIn
                    ? {
                        respondentsCanSelfCheckIn:
                          formState.respondentsCanSelfCheckIn,
                      }
                    : {}),
                  ...(formState.respondentsCanCommentOnTheirCheckIns !==
                  organization.respondentsCanCommentOnTheirCheckIns
                    ? {
                        respondentsCanCommentOnTheirCheckIns:
                          formState.respondentsCanCommentOnTheirCheckIns,
                      }
                    : {}),
                  ...(formState.staffCanSelfCheckIn !==
                  organization.staffCanSelfCheckIn
                    ? {
                        staffCanSelfCheckIn: formState.staffCanSelfCheckIn,
                      }
                    : {}),
                  ...(formState.emailStaffOnSelfCheckIn !==
                  organization.emailStaffOnSelfCheckIn
                    ? {
                        emailStaffOnSelfCheckIn:
                          formState.emailStaffOnSelfCheckIn,
                      }
                    : {}),
                  ...(formState.anonymitySettings !==
                  organization.anonymitySettings
                    ? { anonymitySettings: formState.anonymitySettings }
                    : {}),
                  ...(formState.selfCheckInAnonymitySettings !==
                  organization.selfCheckInAnonymitySettings
                    ? {
                        selfCheckInAnonymitySettings:
                          formState.selfCheckInAnonymitySettings,
                      }
                    : {}),
                },
              });

              console.log(data);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          Save
        </Button>
      </Panel.Footer>
    </Panel.Base>
  );
};

export default OrganizationCheckInSettings;
