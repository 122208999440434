import { useMutation, useApolloClient } from "@apollo/react-hooks";
import { Text, Flex } from "@skodel/sk-ui";
import { ModalCloseButton, Button, Modal, Checkbox } from "@skodel/sk-ui";
import { FormLabel, TextInput } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
// import gql from "graphql-tag";
import { toast } from "react-toastify";

const UPDATE_ORGANIZATION = loader("./UpdateOrganization.graphql");

const EditOrganizationModal = ({
  isOpen,
  onClose,
  callback,
  organization,
  ...otherProps
}: any) => {
  const client = useApolloClient();

  const [formState, setFormState]: any = useState({
    name: organization.name,
    hasExecutiveProduct: organization.hasExecutiveProduct,
  });

  const [updateOrganization, state] = useMutation(UPDATE_ORGANIZATION, {
    refetchQueries: ["GetOrganizations", "GetOrganizationsByName"],
  });

  const shouldInputBeDisabled = formState.name.length === 0 || state.loading;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Organization"
    >
      <Modal.Header>
        <Text>Editing {organization.name}</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="name">
          Name
        </FormLabel>
        <TextInput
          id="name"
          name="name"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              name: e.target.value,
            })
          }
          value={formState.name}
        />
        <Flex alignItems="center" justifyContent="flex-start" mt={2}>
          <Checkbox
            mr={2}
            checked={formState.hasExecutiveProduct}
            onChange={(newValue: any) => {
              setFormState({
                ...formState,
                hasExecutiveProduct: newValue,
              });
            }}
          />{" "}
          <Text>Has Executive Product</Text>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled}
          onClick={() => {
            updateOrganization({
              variables: {
                id: organization.id,
                ...(formState.name === organization.name
                  ? {}
                  : { name: formState.name }),
                ...(formState.hasExecutiveProduct ===
                organization.hasExecutiveProduct
                  ? {}
                  : { hasExecutiveProduct: formState.hasExecutiveProduct }),
                // ...((formState.country?.value || null) === (organization.country?.id || null) ? {} : { countryId: formState.country?.value || null }),                  },
              },
            })
              .then((response: any) => {
                if (response.data.updateOrganization.success) {
                  toast.success("Successfully updated organization.");

                  onClose();
                } else {
                  if (
                    [
                      "NoSuchObjectError",
                      "ValidationError",
                      "OperationForbidden",
                      "OperationNotPermitted",
                    ].indexOf(response.data.updateOrganization.__typename) !==
                    -1
                  ) {
                    toast.error(response.data.updateOrganization.message);
                  } else {
                    toast.error(
                      "Could not update organization. Please check your internet connection and try again."
                    );
                  }
                }
              })
              .catch((err: any) =>
                toast.error(
                  "Could not update organization. Please check your internet connection and try again."
                )
              );
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default EditOrganizationModal;
