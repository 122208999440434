import { Modal } from "@skodel/sk-ui";
import React from "react";

import { Container } from ".";

const AddBulkUsersModal = ({
  isOpen,
  onClose,
  groupValue,
  userValue,
  title,
  preludeText,
  confirmationTextAddendum,
  ...otherProps
}: any) => {
  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={title}
      style={{
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          overflow: "visible",
          width: "700px",
          padding: "none",
          border: "none",
          background: "none",
        },
        overlay: {
          background: "rgba(0,0,0,0.95)",
        },
      }}
    >
      <Container
        title={title}
        preludeText={preludeText}
        confirmationTextAddendum={confirmationTextAddendum}
        onClose={onClose}
      />
    </Modal.Base>
  );
};

export default AddBulkUsersModal;
