import { useQuery } from "@apollo/react-hooks";
import { Flex, Text, Pagination, TextInput, Loader } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import queryString from "query-string";
import React, { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { getNewLocation, pushWithHook } from ".";
import AccountTypeSelect from "../components/account-type-select";
import CsvDownloader, {
  UserEdgeMapper,
} from "../components/user-csv-downloader";
import { UserTable } from "../components/user-table";
import { GetOrganizationUsers } from "./types";

const GET_ORGANIZATION_USERS = loader("./GetOrganizationUsers.graphql");

type UserFeedParams = {
  page?: number;
  count?: number;
  emailQuery?: string;
  nameQuery?: string;
  accountTypeIn?: string[];
  accountTypeNotIn?: string[];
  sort?: string;
  sortDirection?: string;
};

export const getUserFeedParams: (location: any) => UserFeedParams = (
  location: any
) => {
  const query: {
    page?: string;
    count?: string;
    emailQuery?: string;
    nameQuery?: string;
    accountTypeIn?: string;
    accountTypeNotIn?: string;
    sort?: string;
    sortDirection?: string;
  } = queryString.parse(location.search);

  const feedParams: UserFeedParams = {
    page: 1,
    accountTypeIn: [],
    accountTypeNotIn: [],
  };

  if (query.page) {
    feedParams.page = parseInt(query.page || "1");
  }

  if (query.count) {
    feedParams.page = parseInt(query.count || "20");
  }

  if (query.emailQuery) {
    feedParams.emailQuery = query.emailQuery;
  }

  if (query.nameQuery) {
    feedParams.nameQuery = query.nameQuery;
  }

  if (query.sort) {
    feedParams.sort = query.sort;
  }

  if (query.sortDirection) {
    feedParams.sortDirection = query.sortDirection;
  }

  if (query.accountTypeIn) {
    feedParams.accountTypeIn = (query.accountTypeIn || "").split(",");
  }

  if (query.accountTypeNotIn) {
    feedParams.accountTypeNotIn = (query.accountTypeNotIn || "").split(",");
  }

  return feedParams;
};

export const OrganizationUsers = ({
  organizationId,
  accountTypeIn,
  accountTypeNotIn,
  userStatus,
  csvPrefix,
}: {
  organizationId: string;
  csvPrefix: string;
  accountTypeIn?: string[];
  accountTypeNotIn?: string[];
  userStatus?: "ACTIVE" | "DISABLED" | "ANY";
}) => {
  const location = useLocation();
  const history = useHistory();
  const feedParams = getUserFeedParams(location);

  const setNameQuery = useCallback(
    (nameQuery: string) => {
      pushWithHook(
        history,
        getNewLocation(
          {
            ...feedParams,
            page: 1,
            nameQuery,
          } as any,
          location
        )
      );
    },
    [pushWithHook, getNewLocation, history, feedParams, location]
  );

  const setEmailQuery = useCallback(
    (emailQuery: string) => {
      pushWithHook(
        history,
        getNewLocation(
          {
            ...feedParams,
            page: 1,
            emailQuery,
          } as any,
          location
        )
      );
    },
    [pushWithHook, getNewLocation, history, feedParams, location]
  );

  const setaccountTypeInQuery = useCallback(
    (accountTypeIn: string[]) => {
      pushWithHook(
        history,
        getNewLocation(
          {
            ...feedParams,
            page: 1,
            accountTypeIn,
          } as any,
          location
        )
      );
    },
    [pushWithHook, getNewLocation, history, feedParams, location]
  );

  const setCurrentSort = (
    sort: string | null,
    sortDirection: string | null
  ) => {
    pushWithHook(
      history,
      getNewLocation(
        {
          ...feedParams,
          page: 1,
          ...(sort && sortDirection
            ? { sort, sortDirection }
            : {
                sort: undefined,
                sortDirection: undefined,
              }),
        },
        location
      )
    );
  };

  const setaccountTypeNotInQuery = useCallback(
    (accountTypeNotIn: string[]) => {
      pushWithHook(
        history,
        getNewLocation(
          {
            ...feedParams,
            page: 1,
            accountTypeNotIn,
          } as any,
          location
        )
      );
    },
    [pushWithHook, getNewLocation, history, feedParams, location]
  );

  const variables = useMemo(
    () => ({
      id: organizationId,
      ...feedParams,
      accountTypeIn: [
        ...(feedParams.accountTypeIn || []),
        ...(accountTypeIn || []),
      ],
      accountTypeNotIn: [
        ...(feedParams.accountTypeNotIn || []),
        ...(accountTypeNotIn || []),
      ],
      userStatus,
      sorts: feedParams.sort
        ? [`${feedParams.sort}_${feedParams.sortDirection}`]
        : [],
    }),
    [feedParams, accountTypeIn, accountTypeNotIn, userStatus, organizationId]
  );

  const { loading, data, error } = useQuery<GetOrganizationUsers>(
    GET_ORGANIZATION_USERS,
    {
      variables,
    }
  );

  const filterBar = (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      style={{
        gap: "8px",
      }}
      mb="3"
    >
      <TextInput
        placeholder="Type here to search by name"
        value={feedParams.nameQuery}
        onChange={(e) => setNameQuery(e.target.value)}
      />
      <TextInput
        placeholder="Type here to search by email"
        value={feedParams.emailQuery}
        onChange={(e) => setEmailQuery(e.target.value)}
      />
      <AccountTypeSelect
        placeholder="Select account types to include"
        isMulti
        value={feedParams.accountTypeIn}
        onChange={(v: any) => setaccountTypeInQuery(v)}
      />
      <AccountTypeSelect
        placeholder="Select account types to not include"
        isMulti
        value={feedParams.accountTypeNotIn}
        onChange={(v: any) => setaccountTypeNotInQuery(v)}
      />
    </Flex>
  );

  if (!data?.organization) {
    return (
      <Flex flexDirection="column">
        {filterBar}
        <Flex alignItems={"center"} justifyContent="center" width="100%" p={5}>
          <Loader />
        </Flex>
      </Flex>
    );
  }

  const edges = data?.organization?.users?.edges || [];

  if (edges.length === 0) {
    return (
      <Flex flexDirection="column">
        {filterBar}
        <Flex alignItems={"center"} justifyContent="center" width="100%" p={5}>
          <Text fontSize="3">No results.</Text>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex flexDirection="column">
      {filterBar}
      <UserTable
        users={edges.map((edge: any) => edge.node)}
        setCurrentSort={setCurrentSort}
        sort={feedParams.sort}
        sortDirection={feedParams.sortDirection}
      />
      <Flex
        flex="1"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        mt="3"
      >
        <CsvDownloader
          edgeMapper={UserEdgeMapper}
          getConnectionFromData={(data) => data?.organization?.users}
          namePrefix={csvPrefix}
          query={GET_ORGANIZATION_USERS}
          variables={variables}
        />
        <Flex flex="1" justifyContent="flex-end" flexDirection="row">
          {data &&
            data?.organization?.users?.pageInfo &&
            feedParams &&
            getNewLocation && (
              <Pagination
                pageInfo={data?.organization?.users.pageInfo}
                feedParams={feedParams}
                getNewLocation={getNewLocation}
              />
            )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default OrganizationUsers;
