import { useMutation } from "@apollo/react-hooks";
import { Text } from "@skodel/sk-ui";
import {
  ModalCloseButton,
  Button,
  OrganizationSelect,
  Modal,
} from "@skodel/sk-ui";
import { FormLabel, EmailInput, TextInput } from "@skodel/sk-ui";
import { loader } from "graphql.macro";
import React, { useState } from "react";
import { toast } from "react-toastify";

import AccountTypeSelect from "../components/account-type-select";

const CREATE_USER = loader("./CreateUser.graphql");

const CreateUserModal = ({
  isOpen,
  onClose,
  organization,
  ...otherProps
}: any) => {
  const [formState, setFormState]: any = useState({
    firstName: "",
    lastName: "",
    email: "",
    organization: organization,
    accountType: null,
  });

  const [createUser, state] = useMutation(CREATE_USER, {
    refetchQueries: ["GetUsers"],
  });

  const shouldInputBeDisabled =
    formState.firstName.length === 0 ||
    formState.lastName.length === 0 ||
    formState.email.length === 0 ||
    formState.email.indexOf("@") === -1 ||
    formState.organization === null ||
    formState.accountType === null ||
    state.loading;

  return (
    <Modal.Base
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Create User"
    >
      <Modal.Header>
        <Text>Create User</Text>

        <ModalCloseButton onClose={onClose} />
      </Modal.Header>
      <Modal.Body>
        <FormLabel p={2} px={0} htmlFor="firstName">
          First Name
        </FormLabel>
        <TextInput
          id="firstName"
          name="firstName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              firstName: e.target.value,
            })
          }
          value={formState.firstName}
        />

        <FormLabel p={2} px={0} htmlFor="lastName">
          Last Name
        </FormLabel>
        <TextInput
          id="lastName"
          name="lastName"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              lastName: e.target.value,
            })
          }
          value={formState.lastName}
        />

        <FormLabel p={2} px={0} htmlFor="name">
          Email
        </FormLabel>
        <EmailInput
          id="email"
          name="email"
          onChange={(e: any) =>
            setFormState({
              ...formState,
              email: e.target.value,
            })
          }
          value={formState.email}
        />

        <FormLabel p={2} px={0} htmlFor="named">
          Organization
        </FormLabel>

        <OrganizationSelect
          isDisabled={!!organization}
          onChange={(v: any) =>
            setFormState({
              ...formState,
              organization: v,
            })
          }
          value={formState.organization}
        />
        <FormLabel p={2} px={0} htmlFor="accountType">
          Account Type
        </FormLabel>
        <AccountTypeSelect
          name="accountType"
          value={formState.accountType}
          onChange={(v: any) =>
            setFormState({
              ...formState,
              accountType: v,
            })
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" mr="2" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={shouldInputBeDisabled}
          onClick={() => {
            createUser({
              variables: {
                firstName: formState.firstName,
                lastName: formState.lastName,
                email: formState.email,
                organizationId: formState.organization.value,
              },
              refetchQueries: ["GetUsers"],
            })
              .then((data: any) => toast.success("Successfully created user."))
              .catch((err: any) =>
                toast.error(
                  "Could not create user. Please check your internet connection and try again."
                )
              );

            onClose();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal.Base>
  );
};

export default CreateUserModal;
