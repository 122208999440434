import { Text } from "@skodel/sk-ui";
import styled from "styled-components";

const FlowHeading = styled(Text)`
  font-family: "Nunito Sans Bold";
  font-weight: normal;
`;

FlowHeading.defaultProps = {
  ...FlowHeading.defaultProps,
  fontSize: 2,
};

export default FlowHeading;
