import { Flex, Box, Text } from "@skodel/sk-ui";
import Papa from "papaparse";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { BaseModalContent } from "./BaseModal";
import FlowHeading from "./FlowHeading";

const FlexAny: any = Flex;

function MyDropzone({
  style,
  setDataAndIncrementStep,
  ...otherProps
}: {
  style?: any;
  setDataAndIncrementStep: any;
}) {
  const { t } = useTranslation();

  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();

    reader.onabort = () => toast.error("Error loading CSV data");
    reader.onerror = () => toast.error("Error loading CSV data");
    reader.onload = () => {
      const binaryStr = reader.result;
      // @ts-ignore
      const data: any = Papa.parse(binaryStr);

      if (data.errors.length !== 0) {
        toast.error("Invalid CSV data");
        return;
      }

      if (data.data.length === 0) {
        toast.error("CSV must not be empty");
        return;
      }

      if (data.data[0].length < 3) {
        toast.error("CSV must have at least 3 columns");
        return;
      }

      setDataAndIncrementStep(data.data);
    };

    acceptedFiles.forEach((file: any) => reader.readAsBinaryString(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <FlexAny
      {...getRootProps()}
      alignItems="center"
      justifyContent="center"
      p={3}
      my={3}
      style={{
        border: "2px dashed #cccccc",
        ...style,
      }}
      {...otherProps}
    >
      <input {...getInputProps()} />
      <Text
        textAlign="center"
        style={{
          maxWidth: "300px",
        }}
      >
        {isDragActive ? (
          <>
            {t("BulkImport.drop_the_file_here_", {
              defaultValue: "Drop the file here ...",
            })}
          </>
        ) : (
          <>
            {t("BulkImport.drag_n_drop_csv_here_or_click_to_select_", {
              defaultValue: "Drag ‘n’ drop CSV here, or click to select a file",
            })}
          </>
        )}
      </Text>
    </FlexAny>
  );
}

export const UploadPage = ({
  setDataAndIncrementStep,
  onClose,
  title,
  preludeText,
}: any) => {
  return (
    <BaseModalContent
      onClose={onClose}
      title={title}
      body={
        <>
          <FlowHeading mb="2">{preludeText}</FlowHeading>
          <Box mt="3">
            <MyDropzone setDataAndIncrementStep={setDataAndIncrementStep} />
          </Box>
        </>
      }
    />
  );
};
