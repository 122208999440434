import { Flex, Text } from "@skodel/sk-ui";
import React from "react";

export const OrganizationDashboard = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  return (
    <Flex alignItems={"center"} justifyContent="center" width="100%" p={5}>
      <Text fontSize="3">This page is under construction.</Text>
    </Flex>
  );
};

export default OrganizationDashboard;
